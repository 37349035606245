/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Snackbar, MuiAlert, Stack } from '../../utils/components/index';
import { useSelector } from 'react-redux';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Alerts() {
  const { alerts } = useSelector((state) => state.notifications);
  const [alert, setAlert] = React.useState({
    type: undefined,
    message: undefined,
  });
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      setState({ ...state, open: true });
      // setTimeout(() => {
      //   setState({ ...state, open: false });
      // }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, open: false });
    setAlert({ ...alert, ...{ type: undefined, message: undefined } });
  };
  const { vertical, horizontal, open } = state;

  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          //   anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          //   key={vertical + horizontal}
        >
          <Alert onClose={handleClose} severity={alert?.type}>
            {alert?.message}
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}

export default Alerts;
