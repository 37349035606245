import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../components/inside-login/dashboard/Dashboard";
import BlogList from "../components/inside-login/blogs/blogs.list";
import InsideLayout from "../components/layout/InsideLayout";
import ListRoles from "../components/inside-login/roles/roles.list";
import AddRole from "../components/inside-login/roles/add.role";
import EditRole from "../components/inside-login/roles/edit.role";
import AddBlog from "../components/inside-login/blogs/add.blog";
import AddDomain from "../components/inside-login/domains/add.domain";
import EditDomain from "../components/inside-login/domains/edit.domain";
import DomainList from "../components/inside-login/domains/domains.list";
import AddLanguage from "../components/inside-login/languages/add.language";
import LanguageList from "../components/inside-login/languages/languages.list";
import EditLanguage from "../components/inside-login/languages/edit.language";
import NotFound from "../components/errors/404/NotFound";
import Login from "../components/outside-login/login/login";
import OutsideLayout from "../components/layout/OutsideLayout";
import AddCategory from "../components/inside-login/category/add.categories";
import CategoryList from "../components/inside-login/category/categories.list";
import EditCategory from "../components/inside-login/category/edit.category";
import AddSeo from "../components/inside-login/seo/add.seo";
import EditSeo from "../components/inside-login/seo/edit.seo";
import SeoList from "../components/inside-login/seo/seo.list";
import EditBlog from "../components/inside-login/blogs/edit.blog";
import PreviewBlog from "../components/inside-login/blogs/preview.blog";
import TrashList from "../components/inside-login/blogs/trash.blogs";
import TagList from "../components/inside-login/tags/tags.list";
import AddTag from "../components/inside-login/tags/add.tag";
import EditTag from "../components/inside-login/tags/edit.tag";

let Routes = (isLoggedIn) => [
  {
    path: "/",
    element: !isLoggedIn ? <OutsideLayout /> : <Navigate to="/dashboard" />,
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login />, exact: true },
    ],
  },
  {
    path: "dashboard",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "role",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, redirectTo: "list" },
      { path: "add", element: <AddRole /> },
      { path: "edit/:id", element: <EditRole /> },
      { path: "list", element: <ListRoles /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "domain",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, redirectTo: "list" },
      { path: "add", element: <AddDomain /> },
      { path: "edit/:id", element: <EditDomain /> },
      { path: "list", element: <DomainList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "category",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, redirectTo: "list" },
      { path: "add", element: <AddCategory /> },
      { path: "edit/:id", element: <EditCategory /> },
      { path: "list", element: <CategoryList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "language",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, redirectTo: "list" },
      { path: "add", element: <AddLanguage /> },
      { path: "edit/:id", element: <EditLanguage /> },
      { path: "list", element: <LanguageList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "blog",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, element: <BlogList /> },
      { path: "add", element: <AddBlog /> },
      { path: "edit/:id", element: <EditBlog /> },
      { path: "preview/:id", element: <PreviewBlog /> },
      { path: "list", element: <BlogList /> },
      // { path: "trash", element: <TrashList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "tag",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, element: <TagList /> },
      { path: "add", element: <AddTag /> },
      { path: "edit/:id", element: <EditTag /> },
      { path: "list", element: <TagList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "trash",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, element: <TrashList /> },
      { path: "list", element: <TrashList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "seo",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [
      { index: true, redirectTo: "list" },
      { path: "add", element: <AddSeo /> },
      { path: "edit/:id", element: <EditSeo /> },
      { path: "list", element: <SeoList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "logout",
    element: isLoggedIn ? <InsideLayout /> : <Navigate to="/" />,
    children: [{ index: true, element: <BlogList /> }],
  },
];

export default Routes;
