import { createSlice } from '@reduxjs/toolkit';
// import { extraAction } from '../extraAction';
export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alerts: [],
  },
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type,
      });
    },
    deleteAlert: (state) => {
      state.alerts = [];
    },
  },
  extraReducers: {
    // [extraAction]: (state, action) => {
    //   state.alerts.push({ message: action.error.message, type: 'error' });
    // },
  },
});

export const { createAlert, deleteAlert } = alertSlice.actions;
// export const alertSelector = (state) => state.alerts;
export default alertSlice;
