/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { categorySelector, clearState, addCategory } from "./categorySlice";
import { useNavigate } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "../../../utils/components/index";

function AddCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(categorySelector);

  // meta description
  const [metaDescriptionText, setMetaDescriptionText] = useState("");
  const metaDescriptionChangeHandler = (event) => {
    setMetaDescriptionText(event.target.value);
  };

  const onSubmit = (data) => {
    let postData = {
      name: data.categoryName,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription
      // code: data.code,
    }; // name is from backend, categoryName is mentioned in frontend
    dispatch(addCategory(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/category/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Category
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="category_name"
              id="category_name"
              required
              fullWidth
              autoComplete="off"
              label="Category Name"
              inputProps={register("categoryName", validations.category_name)}
              error={errors.categoryName}
              helperText={errors.categoryName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="metaTitle"
              required
              fullWidth
              label="Meta Title"
              placeholder="Maximum 60 characters"
              type="text"
              id="seoTitle"
              inputProps={register("metaTitle", validations.seo_title)}
              error={errors.metaTitle}
              helperText={errors.metaTitle?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="metaDescription"
              id="meta-description"
              required
              fullWidth
              autoComplete="off"
              label="Meta Description"
              placeholder="Maximum 160 characters with space"
              multiline
              rows={1}
              value={metaDescriptionText}
              onChange={metaDescriptionChangeHandler}
              inputProps={register(
                "metaDescription",
                validations.meta_description
              )}
              error={errors.metaDescription}
              helperText={errors.metaDescription?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("metaDescription")?.length}
              </p>
            </span>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="code"
              id="code"
              required
              fullWidth
              autoComplete="off"
              label="Code"
              inputProps={register("code", validations.category_code)}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid> */}
        </Grid>
        <Button
          type="submit"
          disabled={isFetching ? true : false}
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : "Add"}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default AddCategory;
