const getToken = () => {
  let user_credentials = localStorage.getItem('user-credentials');
  if (user_credentials !== null && isJson(user_credentials)) {
    user_credentials = isJson(user_credentials);
    if (
      user_credentials.hasOwnProperty('token') &&
      typeof user_credentials['token'] === 'string' &&
      user_credentials['token'].length
    ) {
      return user_credentials['token'];
    } else {
      return false;
    }
  } else {
    return false;
  }
};

function isJson(str) {
  try {
   return JSON.parse(str);
  } catch (e) {
    return false;
  } 
}

export default getToken;
