import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const loginUser = createAsyncThunk(
  'auth/login',
  async (postData, { rejectWithValue }) => {
    try {
      let userLoginDetails = await HTTPClient.login(postData);
      return userLoginDetails;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyUser = createAsyncThunk('auth/verify', async (thunkAPI) => {
  try {
    let url = `auth/verify`;
    const verify = await HTTPClient.http_get(url);
    return verify;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const initialState = {
  isLoggedIn: false,
  isFetching: false,
  isSuccess: false,
  isError: false,
  responseMsg: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset(state) {
      localStorage.removeItem('user-credentials');
      Object.assign(state, initialState);
    },
    logoutUser(state) {
      localStorage.removeItem('user-credentials');
      Object.assign(state, initialState);
    },
    clearState(state) {
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isError: false,
        responseMsg: '',
      };
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.token = payload.token;
        localStorage.setItem(
          'user-credentials',
          JSON.stringify({ token: payload.token })
        );
        state.isFetching = false;
        state.isSuccess = true;
        state.isLoggedIn = true;
        state.isError = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isFetching = false;
        state.isSuccess = false;
        state.isLoggedIn = false;
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isLoggedIn = false;
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [verifyUser.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [verifyUser.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload?.status) {
        if (payload?.expire) {
          localStorage.removeItem('user-credentials');
          Object.assign(state, initialState);
          return state;
        }
      } else {
        state.isFetching = false;
        state.isSuccess = false;
        state.isLoggedIn = false;
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [verifyUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isLoggedIn = false;
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState, reset, logoutUser } = authSlice.actions;
export const authSelector = (state) => state.auth;
