/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { domainSelector, clearState, addDomain } from "./domainSlice";
import { useNavigate } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";

import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "../../../utils/components/index";
import Alerts from "../../alert/alerts";
import { createAlert, deleteAlert } from '../../alert/alertSlice';

function AddDomain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(domainSelector);

  const onSubmit = (data) => {
    let postData = { name: data.name, url: data.domainUrl }; // 'name' is from backend, 'domain_name' is from frontend.
    dispatch(addDomain(postData));
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: 'success',
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate('/domain/list');
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: 'error',
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);
  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Domain
      </Typography>

      {/* Alert Section start */}
      {/* {alertSection} */}
      {/* Alert Section end */}

      {/* {isSuccess ? <Alerts type={"success"} message={responseMsg} /> : ""}
      {isError ? <Alerts type={"error"} message={responseMsg} /> : ""} */}
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="domain_name"
              id="domain_name"
              required
              fullWidth
              autoComplete="off"
              label="Domain Name"
              autoFocus
              inputProps={register("name", validations.domain_name)}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="domainUrl"
              label="Domain URL"
              name="domainUrl"
              autoComplete="off"
              inputProps={register("domainUrl", validations.domain_url)}
              error={errors.domainUrl}
              helperText={errors.domainUrl?.message}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          disabled={isFetching ? true : false}
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : "Add"}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default AddDomain;
