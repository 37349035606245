const validations = {
  email: {
    required: "Email is required",
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@\\']+(\.[^<>()[\]\\.,;:\s@\\']+)*)|(\\'.+\\'))@(([^<>()[\]\\.,;:\s@\\']+\.)+[^<>()[\]\\.,;:\s@\\']{1,})$/i,
      message: "Invalid email",
    },
  },

  password: {
    required: {
      value: true,
      message: "Password is required",
    },
    minLength: {
      value: 5,
      message: "Password must be greater than 5 characters.",
    },
  },

  domain_name: {
    required: "Domain name is required",
    pattern: {
      value: /^[a-zA-Z0-9][a-zA-Z0-9-(\s)?(\\.)?]{1,61}[a-zA-Z0-9]$/,
      message: "Only characters, dots and spaces are allowed",
    },
  },

  domain_id: {
    required: "Domain is required",
    pattern: {
      value: /[a-z|0-9]+/i,
      message: "Invalid Domain",
    },
  },

  domain_url: {
    required: "Domain URL is required",
    pattern: {
      value:
        /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
      message: "Invalid url format",
    },
  },

  name: {
    required: "Name is required",
    pattern: {
      value: /[a-z|A-Z, ]+/i,
      message: "Invalid name format",
    },
  },

  blog_author: {
    required: "Author is required",
    pattern: {
      value: /[a-z|A-Z, ]+/i,
      message: "Only characters and spaces are allowed",
    },
  },

  // author: {
  //   required: 'Author is required',
  //   pattern: {
  //     value: /[a-z|A-Z|0-9|, ]+/i,
  //     message: 'Only characters and spaces are allowed',
  //   },
  // },

  // h2: {
  //   required: "H2 is required",
  //   pattern: {
  //     value: /[a-z|A-Z|0-9|, ]+/i,
  //     message: "Only characters, numbers and spaces are allowed",
  //   },
  // },

  banner_image_alt_tag: {
    required: "Banner Image Alt Tag is required",
    pattern: {
      value: /[a-z|A-Z|0-9|, ]+/i,
      message: "Only characters, numbers and spaces are allowed",
    },
  },

  // thumbnailImg: {
  //   required: "Thumbnail image is required",
  // },

  // bannerImg: {
  //   required: "Banner image is required",
  // },

  title: {
    required: "Title is required",
    pattern: {
      value: /[a-z|A-Z|0-9|, ]+/i,
      message: "Only characters, numbers and spaces are allowed",
    },
  },

  seo_title: {
    required: "SEO Title is required",
    pattern: {
      value: /[a-z|A-Z|0-9|, ]+/i,
      message: "Only characters, numbers and spaces are allowed",
    },
    maxLength: {
      value: 60,
      message: "Maximum character is 60",
    },
  },

  role_name: {
    required: "Role name is required",
    pattern: {
      // value: /^[a-zA-Z0-9][a-zA-Z0-9-(\s)?(\\.)?]{1,61}[a-zA-Z0-9]$/,
      message: "Only characters, dots and spaces are allowed",
    },
  },

  role_code: {
    required: "Role Code is required",
    pattern: {
      // value: /^[a-zA-Z0-9][a-zA-Z0-9-(\s)?(\\.)?]{1,61}[a-zA-Z0-9]$/,
      message: "Only characters, dots and spaces are allowed",
    },
  },

  language_name: {
    required: "Language is required",
    pattern: {
      value: /^[a-z|A-Z]+$/,
      message: "Only alphabets are allowed",
    },
  },

  language_id: {
    required: "Language is required",
    pattern: {
      value: /[a-z|0-9|_]+/i,
      message: "Invalid Language",
    },
  },

  language_code: {
    required: "Language Code is required",
    pattern: {
      // value: /^[a-zA-Z0-9][a-zA-Z0-9-(\s)?(\\.)?]{1,61}[a-zA-Z0-9]$/,
      message: "Only characters, dots and spaces are allowed",
    },
  },

  category_id: {
    required: "Category is required",
    pattern: {
      value: /[a-z|0-9]+/i,
      message: "Invalid Category",
    },
  },

  category_name: {
    required: "Category Name is required",
    pattern: {
      value: /[a-z|A-Z|0-9|, ]+/i,
      message: "Only characters, numbers and spaces are allowed",
    },
  },

  category_code: {
    required: "Category Code is required",
    pattern: {
      // value: /^[a-zA-Z0-9][a-zA-Z0-9-(\s)?(\\.)?]{1,61}[a-zA-Z0-9]$/,
      message: "Only characters, dots and spaces are allowed",
    },
  },

  description: {
    required: "Description is required",
  },

  publish_date: {
    required: "Publish Date is required",
  },

  robots: {
    required: "Robots is required",
  },

  canonical: {
    required: "Canonical URL is required",
    pattern: {
      value:
        /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
      message: "Invalid url format",
    },
  },

  banner_image: {
    required: "Banner Image is required",
  },
  
  small_banner_image: {
    required: "Thumbnail image is required",
  },

  blog_type: {
    required: "Blog type is required",
  },

  status: {
    required: "Status is required",
  },

  meta_description: {
    required: {
      value: true,
      message: "Meta description is required",
    },
    maxLength: {
      value: 160,
      message: "Maximum character is 160 with whitespaces",
    },
  },
  
  slug: {
    required: "Slug is required",
  },

  short_description: {
    required: {
      value: true,
      message: "Short description is required",
    },
    maxLength: {
      value: 600,
      message: "Maximum character is 600",
    },
  },

  tag: {
    required: {
      value: true,
      message: "Tag is required",
    },
  }
};

export default validations;
