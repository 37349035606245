/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { languageSelector, clearState, addLanguage } from './languageSlice';
import { useNavigate } from 'react-router-dom';
import validations from '../../../utils/validations/validation.rules';
import { useForm } from 'react-hook-form';
import { createAlert, deleteAlert } from '../../alert/alertSlice';
import Alerts from '../../alert/alerts';
import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from '../../../utils/components/index';

function AddLanguage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(languageSelector);

  const onSubmit = (data) => {
    let postData = { name: data.language, code: data.code };
    dispatch(addLanguage(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: 'success',
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate('/language/list');
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: 'error',
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Language
      </Typography>
      {/* <Notification type={'success'} message={'responseMsg'} /> */}
      {/* {isSuccess ? <Notification type={'success'} message={responseMsg} /> : ''}
      {isError ? <Notification type={'error'} message={responseMsg} /> : ''} */}
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="language_name"
              id="language_name"
              required
              fullWidth
              autoComplete="off"
              label="Language Name"
              autoFocus
              inputProps={register('language', validations.language_id)}
              error={errors.language}
              helperText={errors.language?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="language_code"
              label="Language Code"
              name="language_code"
              autoComplete="off"
              inputProps={register('code', validations.language_code)}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : 'Add'}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default AddLanguage;
