/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listOfDeletedBlogs, clearState, deleteBlog } from "./blogSlice";
import ReactTable from "../../../utils/react-table/ReactTable";
import { blogSelector } from "./blogSlice";
import Loader from "../../../utils/components/loader";

import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DeleteOutlineTwoToneIcon,
} from "../../../utils/components/index";

function TrashList() {
  let dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const deletedBlogsData = useSelector((state) => state.blogs.blogs);
  let totalDeletedBlogs = useSelector((state) => state.blogs.totalBlogs);

  const { isFetching } = useSelector(blogSelector);

  const totalPages = useSelector((state) => {
    return state.blogs.totalBlogs > 0
      ? Math.ceil(state.blogs.totalBlogs / pageSize)
      : 0;
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(undefined);

  function openDeleteModel(data) {
    setCurrentBlog(data._id);
    updateModalState();
  }

  function updateModalState() {
    setModalOpen(!modalOpen);
  }

  function deleteRowAction(data) {
    if (data) {
      dispatch(deleteBlog(data));
      updateModalState();
      dispatch(clearState());
    }
  }

  const columnsData = [
    {
      Header: "Domain",
      accessor: "domain.name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        )
      }
    },
    {
      Header: "Category",
      accessor: "category.name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "maroon", fontWeight: "bold" }}>{value}</span>
          </div>
        )
      }
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "tomato", fontWeight: "bold" }}>{value}</span>
          </div>
        )
      }
    },
    {
      Header: "Type",
      accessor: "status",
      Cell: (props) => {
        return (
          <p
            style={{
              color: props.value !== "draft" ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {props?.value?.charAt(0).toUpperCase() + props?.value?.slice(1)}
            {/*  the charAt(0) method is used to get the first character of the value string, and the toUpperCase() method is used to capitalize it. Then, the slice(1) method is used to get the rest of the string after the first character, and it's concatenated with the capitalized first character using the + operator. */}
          </p>
        );
      },
    },
    {
      Header: "Action",
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <DeleteOutlineTwoToneIcon
            className="cursor-pointer"
            onClick={() => openDeleteModel(originalRow)}
            titleAccess="Delete Blog"
          />
        </React.Fragment>
      ),
    },
  ];

  const columns = useMemo(() => columnsData, [deleteRowAction]);
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(listOfDeletedBlogs(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ""}
        <Grid item xs={12}>
          <Typography variant="h5" component="div">
            List of Deleted Blogs
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={deletedBlogsData}
            fetchData={fetchData}
            totalRecords={totalDeletedBlogs}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
      <Grid>
        {/* <Modal
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // toggle={() => updateModalState()}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to delete this blog?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign="right">
              <Button
                color="primary"
                onClick={() => deleteRowAction(currentBlog)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </Typography>
          </Box>
        </Modal> */}
        <Dialog
          open={modalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Remove Blog</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this blog permanently?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteRowAction(currentBlog)}>Yes</Button>
            <Button onClick={() => updateModalState()}>No</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}

export default TrashList;
