/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import validations from "../../../utils/validations/validation.rules";
import { roleSelector, clearState, editRole } from "./roleSlice";
import { useNavigate, useParams } from "react-router-dom";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from "../../../utils/components/index";

const EditRole = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rolesData = useSelector((state) => state.roles.roles);
  const { isSuccess, isError, responseMsg } = useSelector(roleSelector);

  const data = rolesData.filter((data) => {
    return data._id === id;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });
  const onSubmit = (data) => {
    let postData = { name: data.name, code: data.code, id: id };
    dispatch(editRole(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/role/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit Role
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              focused
              autoComplete="off"
              name="name"
              required
              fullWidth
              id="roleName"
              label="Role Name"
              autoFocus
              inputProps={register("name", validations.role_name)}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              focused
              required
              fullWidth
              id="roleCode"
              label="Role Code"
              name="code"
              autoComplete="off"
              inputProps={register("code", validations.role_code)}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Edit
          {/* {isFetching ? <CircularProgress color="secondary" /> : "Add"} */}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default EditRole;
