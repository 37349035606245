const configEnv = ()=>{

    let hostname = window.location.hostname;

    let base_url=``;
    if (hostname=='devblogpanel.pellets.supply') {
        base_url = `https://devapiblogpanel.pellets.supply/api/v1/`;
    } else if(hostname=='stagingblogpanel.pellets.supply') {
        base_url = `https://stagingapiblogpanel.pellets.supply/api/v1/`;
    } else if(hostname=='blogpanel.timber.exchange') {
        base_url = `https://blogpanelprodapi.timber.exchange/api/v1/`;
    } else if(hostname=='localhost') {
        base_url = `http://localhost:4000/api/v1/`;
    }

    return base_url;
}

export {configEnv}