import React from 'react';
import SidebarStyle from './Sidebar.styles';
import SidebarMenuItems from './SidebarMenuItems';

import {
  Box,
  Drawer,
  Toolbar
} from '../../../utils/components/index';

function Sidebar(props) {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        className={SidebarStyle.sidebarBackground}
        container={props.container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: props.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: props.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <SidebarMenuItems />
        </Box>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: props.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: props.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <SidebarMenuItems />
        </Box>
      </Drawer>
    </Box>
  );
}

export default Sidebar;
