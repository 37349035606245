import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // PostAddIcon,
  CategoryRounded,
  Domain,
  HomeIcon,
  // TranslateIcon,
  // ListAltIcon,
  DescriptionIcon,
  AutoDeleteIcon,
  LocalOfferIcon,
} from '../../../utils/components/index';

export default function SidebarMenuItems({ handleDrawerClose }) {
  let navigate = useNavigate();
  const menuItems = [
    {
      text: 'Dashboard',
      icon: <HomeIcon />,
      path: '/',
    },
    // {
    //   text: 'Roles',
    //   icon: <ListAltIcon />,
    //   path: '/role/list',
    // },
    // { icon: <PostAddIcon />, text: 'SEO', path: '/seo/list' },
    {
      text: 'Domains',
      icon: <Domain />,
      path: '/domain/list',
    },
    // {
    //   text: 'Languages',
    //   icon: <TranslateIcon />,
    //   path: '/language/list',
    // },
    {
      text: 'Category',
      icon: <CategoryRounded />,
      path: '/category/list',
    },
    {
      text: 'Blogs',
      icon: <DescriptionIcon />,
      path: '/blog/list',
    },
    {
      text: 'Blog Tags',
      icon: <LocalOfferIcon />,
      path: '/tag/list',
    },
    {
      text: 'Trash',
      icon: <AutoDeleteIcon />,
      path: '/trash/list',
    },
  ];
  return (
    <List>
      {menuItems.map((item) => (
        <ListItem key={item.text} onClick={() => navigate(item.path)}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text}></ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
