/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { blogList, clearState, moveBlogToTrash } from "./blogSlice";
import ReactTable from "../../../utils/react-table/ReactTable";
import { blogSelector } from "./blogSlice";
import Loader from "../../../utils/components/loader";

import {
  Add,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PreviewIcon,
  EditTwoToneIcon,
  DeleteOutlineTwoToneIcon,
} from "../../../utils/components/index";

function BlogsList() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const blogsData = useSelector((state) => state.blogs.blogs);
  let totalBlogs = useSelector((state) => state.blogs.totalBlogs);

  const { isFetching } = useSelector(blogSelector);

  const totalPages = useSelector((state) => {
    return state.blogs.totalBlogs > 0
      ? Math.ceil(state.blogs.totalBlogs / pageSize)
      : 0;
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [currentBlog, setCurrentBlog] = useState(undefined);

  function openDeleteModel(data) {
    setCurrentBlog(data._id);
    updateModalState();
  }

  function updateModalState() {
    setModalOpen(!modalOpen);
  }

  function deleteRowAction(data) {
    if (data) {
      dispatch(moveBlogToTrash(data));
      updateModalState();
      dispatch(clearState());
    }
  }

  function previewRowAction(data) {
    dispatch(clearState());
    return navigate(`/blog/preview/${data._id}`);
  }

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/blog/edit/${data._id}`);
  }

  const columnsData = [
    {
      Header: "Domain",
      accessor: "domain.name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category.name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "maroon", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "tomato", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "status",
      Cell: (props) => {
        return (
          <span
            style={{
              color: props?.value !== "draft" ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {props?.value?.charAt(0).toUpperCase() + props?.value?.slice(1)}
          </span>
        );
      },
      // charAt(0): This method returns the first character of the string.

      //toUpperCase(): This method converts the first character to uppercase.

      //slice(1): This method returns the rest of the string starting from the second character, since the first character has already been capitalized.
    },
    {
      Header: "Publish Date",
      accessor: "publishDate",
      Cell: ({ value }) => {
        return (
          <span
            style={{
              color: "olive",
              fontWeight: "bold",
            }}
          >
            {value ? format(new Date(value), "dd/MM/yyyy") : ""}
          </span>
        );
        // return value ? format(new Date(value), "dd/MM/yyyy") : "";
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        const data = row?.original?.publishDate;
        return data === null ? (
          <React.Fragment>
            <PreviewIcon
              className="cursor-pointer"
              onClick={() => previewRowAction(row.original)}
              titleAccess="Preview Blog"
            />
            <EditTwoToneIcon
              className="cursor-pointer"
              onClick={() => editRowAction(row.original)}
              titleAccess="Edit Blog"
            />
            <DeleteOutlineTwoToneIcon
              className="cursor-pointer"
              onClick={() => openDeleteModel(row.original)}
              titleAccess="Move To Trash"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PreviewIcon
              className="cursor-pointer"
              onClick={() => previewRowAction(row.original)}
              titleAccess="Preview Blog"
            />
            <EditTwoToneIcon
              className="cursor-pointer"
              onClick={() => editRowAction(row.original)}
              titleAccess="Edit Blog"
            />
          </React.Fragment>
        );
      },
    },
  ];

  const columns = useMemo(
    () => columnsData,
    [previewRowAction, editRowAction, deleteRowAction]
  );
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(blogList(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ""}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            Blogs
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/blog/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={blogsData}
            fetchData={fetchData}
            totalRecords={totalBlogs}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
      <Grid>
        {/* <Modal
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          // toggle={() => updateModalState()}
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you really want to delete this blog?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign="right">
              <Button
                color="primary"
                onClick={() => deleteRowAction(currentBlog)}
              >
                Yes
              </Button>{" "}
              <Button onClick={() => updateModalState()}>No</Button>
            </Typography>
          </Box>
        </Modal> */}
        <Dialog
          open={modalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Remove Blog</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to move this blog to trash?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteRowAction(currentBlog)}>Yes</Button>
            <Button onClick={() => updateModalState()}>No</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}

export default BlogsList;
