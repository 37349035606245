import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addRole = createAsyncThunk(
  'role/add', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let addRole = await HTTPClient.http_post('roles/store', postData); // this is from backend url
      return addRole;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const roleList = createAsyncThunk(
  'role/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `roles`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `roles?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteRole = createAsyncThunk(
  'role/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteRole = await HTTPClient.http_delete(`roles/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteRole;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editRole = createAsyncThunk(
  'role/update', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    let id = postData.id;
    delete postData[id];
    try {
      let editRole = await HTTPClient.http_patch('roles/' + id, postData); // this is from backend url
      return editRole;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  roles: [],
  totalRoles: 0,
};

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    clearState(state) {
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isError: false,
        responseMsg: '',
      };
    },
  },
  extraReducers: {
    [addRole.pending]: (state) => {
      state.isFetching = true;
    },
    [addRole.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.roles.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isFetching = false;
        state.isSuccess = false;
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [addRole.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [roleList.pending]: (state) => {
      state.isFetching = true;
    },
    [roleList.fulfilled]: (state, { payload }) => {
      if (payload.data !== undefined && payload.statusCode === 200) {
        // let currentState = current(state.roles);
        // let uniqueResultOld = currentState.filter(function (obj) {
        //   return !payload.data.some(function (obj2) {
        //     return obj._id === obj2._id;
        //   });
        // });

        // //Find values that are in result2 but not in result1
        // let uniqueResultNew = payload.data.filter(function (obj) {
        //   return !currentState.some(function (obj2) {
        //     return obj._id === obj2._id;
        //   });
        // });

        // //Combine the two arrays of unique entries
        // var totalRolesList = uniqueResultOld.concat(uniqueResultNew);
        // state.roles = totalRolesList;
        // state.roles = [...state.roles, ...payload.data];
        state.roles = payload.data;
        state.totalRoles = payload.total;
        state.isSuccess = true;
      } else {
        state.isError = true;
      }
      return state;
    },
    [roleList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMgs =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editRole.pending]: (state) => {
      state.isFetching = true;
    },
    [editRole.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.roles.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [editRole.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [deleteRole.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteRole.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.roles = state.roles.filter(
          (role) => role._id !== payload.removeId
        );
        state.totalRoles = state.totalRoles - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteRole.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = roleSlice.actions;
export const roleSelector = (state) => state.roles;
