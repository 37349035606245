/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { domainSelector, clearState, editDomain } from "./domainSlice";
import { useNavigate, useParams } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "../../../utils/components/index";

const EditDomain = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const domainData = useSelector((state) => state.domains.domains);
  const { isSuccess, isFetching, isError, responseMsg } =
    useSelector(domainSelector);

  const data = domainData.filter((data) => {
    return data._id === id;
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });

  const onSubmit = (data) => {
    let postData = {
      name: data.name,
      url: data.url,
      id: id,
      isActive: data.isActive,
    }; // left side name is from backend, right side name is from frontend.
    dispatch(editDomain(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/domain/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit Domain
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="domain_name"
              id="name"
              required
              focused
              fullWidth
              autoComplete="off"
              label="Domain Name"
              autoFocus
              inputProps={register("name", validations.domain_name)}
              error={errors.domainName}
              helperText={errors.domainName?.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              focused
              fullWidth
              id="domainUrl"
              label="Domain URL"
              name="url"
              autoComplete="off"
              inputProps={register("url", validations.domain_url)}
              error={errors.url}
              helperText={errors.url?.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl css-md26zr-MuiInputBase-root-MuiOutlinedInput-root"
              sx={{ width: "100%" }}
            >
              <InputLabel id="status-label">Status *</InputLabel>
              <Select
                fullWidth
                required
                name="isActive"
                id="status"
                defaultValue={data[0]?.isActive}
                label="Status *"
                // onChange={handleBlogStatusType}
                error={errors.status}
                inputProps={register("isActive", validations.status)}
              >
                <MenuItem key="ac" value="true">
                  Active
                </MenuItem>
                <MenuItem key="inac" value="false">
                  Inactive
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          disabled={isFetching ? true : false}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : "Update"}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default EditDomain;
