import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addDomain = createAsyncThunk(
  'domain/add',
  async (postData, { rejectWithValue }) => {
    try {
      let addDomain = await HTTPClient.http_post('domains/store', postData);
      return addDomain;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const domainList = createAsyncThunk(
  'domain/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `domains`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `domains?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const activeDomainList = createAsyncThunk( // this is for domain dropdown at add blog
  'active-domain',
  async (thunkAPI) => {
    try {
      const activeDomain = await HTTPClient.http_get('domains/get-domains/active')
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return activeDomain;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const allDomainList = createAsyncThunk( // this is for domain dropdown at edit blog
  'all-domain',
  async (thunkAPI) => {
    try {
      const allDomain = await HTTPClient.http_get('domains/get-domains/all')
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return allDomain;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);


export const deleteDomain = createAsyncThunk(
  'domain/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteDomain = await HTTPClient.http_delete(`domains/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteDomain;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editDomain = createAsyncThunk(
  'domain/update', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    let id = postData.id;
    delete postData[id];
    try {
      let editDomain = await HTTPClient.http_patch('domains/' + id, postData); // this is from backend url
      return editDomain;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  domains: [],
  totalDomains: 0,
};

export const domainSlice = createSlice({
  name: 'domains',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
  },
  extraReducers: {
    [addDomain.pending]: (state) => {
      state.isFetching = true;
    },
    [addDomain.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.domains.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [addDomain.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [domainList.pending]: (state) => {
      state.isFetching = true;
    },
    [domainList.fulfilled]: (state, { payload }) => {
      if (payload.data !== undefined && payload.statusCode === 200) {
        state.domains = payload.data;
        state.totalDomains = payload.total;
        state.isSuccess = true;
      } else {
        state.isError = true;
      }
      return state;
    },
    [domainList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [activeDomainList.pending]: (state) => {
      state.isFetching = true;
    },
    [activeDomainList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.domains = payload.data;
        state.totalDomains = payload.total;
        state.isSuccess = true;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [activeDomainList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [allDomainList.pending]: (state) => {
      state.isFetching = true;
    },
    [allDomainList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.domains = payload.data;
        state.totalDomains = payload.total;
        state.isSuccess = true;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [allDomainList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editDomain.pending]: (state) => {
      state.isFetching = true;
    },
    [editDomain.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.domains.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [editDomain.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [deleteDomain.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteDomain.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.domains = state.domains.filter(
          (domain) => domain._id !== payload.removeId
        );
        state.totalDomains = state.totalDomains - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteDomain.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = domainSlice.actions;
export const domainSelector = (state) => state.domains;

