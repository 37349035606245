/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { languageList } from "../languages/languageSlice";
import { seoSelector, clearState, editSeo } from "./seoSlice";
import { domainList } from "../domains/domainSlice";
import { useNavigate, useParams } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
} from "../../../utils/components/index";

const EditSeo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const seosData = useSelector((state) => state.seos.seos);
  const domainData = useSelector((state) => state.domains.domains);
  const languageData = useSelector((state) => state.languages.languages);
  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(seoSelector);

  const data = seosData.filter((data) => {
    return data._id === id;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });

  const onSubmit = (data) => {
    let postData = {
      title: data.title,
      description: data.description,
      author: data.author,
      languageId: data.languageId,
      domainId: data.domainId,
      canonicalUrl: data.canonicalUrl,
      id: id,
    }; // left side is from backend, right side is mentioned in frontend
    dispatch(deleteAlert());
    dispatch(clearState());
    dispatch(editSeo(postData));
  };

  useEffect(() => {
    dispatch(clearState());
    if (Object.keys(domainData).length === 0 || domainData.length <= 0) {
      dispatch(domainList());
    }
    if (Object.keys(languageData).length === 0 || languageData.length <= 0) {
      dispatch(languageList());
    }
  }, []);

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/seo/list");
      }, 1200);
    } else if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit SEO
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="title"
              id="title"
              required
              fullWidth
              autoComplete="off"
              label="Title"
              autoFocus
              inputProps={register("title", validations.title)}
              error={errors.title}
              helperText={errors.title?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="robots"
              id="robots"
              required
              fullWidth
              autoComplete="off"
              label="Robots"
              inputProps={register("robots", validations.robots)}
              error={errors.robots}
              helperText={errors.robots?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="author"
              id="author"
              required
              fullWidth
              autoComplete="off"
              label="Author"
              inputProps={register("author", validations.author)}
              error={errors.author}
              helperText={errors.author?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="revisitAfter"
              id="revisitAfter"
              required
              fullWidth
              autoComplete="off"
              label="Revisit After"
              inputProps={register("revisitAfter", validations.revisitAfter)}
              error={errors.revisitAfter}
              helperText={errors.revisitAfter?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue={data[0]?.languageId}
              label="Language *"
              inputProps={register("languageId", validations.language_id)}
              error={errors.language_id}
              helperText={errors.language_id?.message}
            >
              {languageData.map((language) => (
                <MenuItem key={language._id} value={language._id}>
                  {language.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue={data[0]?.domainId}
              label="Domain *"
              inputProps={register("domainId", validations.domain_id)}
              error={errors.domain_id}
              helperText={errors.domain_id?.message}
            >
              {domainData.map((domain) => (
                <MenuItem key={domain._id} value={domain._id}>
                  {domain.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="canonicalUrl"
              id="canonicalUrl"
              required
              fullWidth
              autoComplete="off"
              label="Canonical URL"
              inputProps={register("canonicalUrl", validations.canonical_url)}
              error={errors.canonical_url}
              helperText={errors.canonical_url?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue=""
              label="Twitter Card *"
              inputProps={register(
                "twitter_card",
                validations.twitter_card
              )}
              error={errors.twitter_card}
              helperText={errors.twitter_card?.message}
            >
              <MenuItem value="summary">
                Summary
              </MenuItem>
              <MenuItem value="summary_large_image">
                Summary Large Image
              </MenuItem>
              <MenuItem value="app">
                App
              </MenuItem>
              <MenuItem value="player">
                Player
              </MenuItem>

              {/* {languageData.map((language) => (
                <MenuItem key={language.code} value={language._id}>
                  {language.name}
                </MenuItem>
              ))} */}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              focused
              autoComplete="off"
              name="image"
              required
              fullWidth
              label="Image"
              type="file"
              id="image"
              accept="image/*"
              inputProps={register("image", validations.image)}
              error={errors.image}
              helperText={errors.image?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="short_code"
              id="short_code"
              required
              fullWidth
              placeholder="ex. Blog details"
              autoComplete="off"
              label="Short Code"
              autoFocus
              inputProps={register("short_code", validations.short_code)}
              error={errors.short_code}
              helperText={errors.short_code?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="description"
              id="description"
              required
              fullWidth
              autoComplete="off"
              label="Description"
              multiline
              rows={2}
              inputProps={register("description", validations.description)}
              error={errors.description}
              helperText={errors.description?.message}
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          {isFetching ? <CircularProgress color="secondary" /> : "Edit"}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default EditSeo;
