import React from 'react';
import { Outlet } from 'react-router-dom';

import {
  Box,
  CssBaseline,
  Toolbar
} from '../../utils/components/index';

const OutsideLayout = () => {

  return (
    <React.Fragment>
      <div className="outside-layout">
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Toolbar />
          <Outlet />
        </Box>
      </div>
    </React.Fragment>
  );
};

export default OutsideLayout;
