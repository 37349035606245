import React from 'react';

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  MenuIcon,
  IconButton,
  NavProfile,
} from '../../../utils/components/index';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  header_background: {
    backgroundColor: theme.palette.primary.main,
  },
}));
const Header = (props) => {
  const classes = useStyles();
  return (
    <AppBar
      // color="secondary"
      className={classes.header_background}
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
          <IconButton onClick={props.handleDrawerToggle} color="inherit">
            <MenuIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Centersource
        </Typography>
        <NavProfile {...props} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
