/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { languageSelector, clearState, editLanguage } from "./languageSlice";
import { useNavigate, useParams } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "../../../utils/components/index";

const EditLanguage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageData = useSelector((state) => state.languages.languages);
  const data = languageData.filter((data) => {
    return data._id === id;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(languageSelector);

  const onSubmit = (data) => {
    let postData = { name: data.name, code: data.code, id: id };
    dispatch(editLanguage(postData));
  };
  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/language/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit Language
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              id="language_name"
              focused
              required
              fullWidth
              autoComplete="off"
              label="Language Name"
              autoFocus
              inputProps={register("name", validations.language_name)}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              focused
              required
              fullWidth
              id="language_code"
              label="Language Code"
              name="code"
              autoComplete="off"
              inputProps={register("code", validations.language_code)}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          {isFetching ? <CircularProgress color="secondary" /> : "Edit"}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default EditLanguage;
