/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  Grid,
  Button,
  Typography,
  EditTwoToneIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DeleteOutlineTwoToneIcon
} from '../../../utils/components/index';
import { Link, useNavigate } from 'react-router-dom';
import { roleList, clearState, deleteRole } from './roleSlice';
import ReactTable from '../../../utils/react-table/ReactTable';
import { roleSelector } from './roleSlice';
import Loader from '../../../utils/components/loader';

function RolesList() {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);

  const { isFetching } = useSelector(roleSelector);
  
  const rolesData = useSelector((state) => state.roles.roles);
  let totalRoles = useSelector((state) => state.roles.totalRoles);
  totalRoles = totalRoles !== undefined && totalRoles > 0 ? totalRoles : 0;

  const totalPages = useSelector((state) => {
    return state.roles.totalRoles > 0
      ? Math.ceil(state.roles.totalRoles / pageSize)
      : 0;
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(undefined);

  function openDeleteModel(data) {
    setCurrentRole(data._id);
    updateModalState();
  }

  function updateModalState() {
    setModalOpen(!modalOpen);
  }

  function deleteRowAction(data) {
    if (data) {
      dispatch(deleteRole(data));
      updateModalState();
      dispatch(clearState());
    }
  }

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/role/edit/${data._id}`);
  }

  const columnsData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <EditTwoToneIcon
            className="cursor-pointer"
            onClick={() => editRowAction(originalRow)}
            titleAccess="Edit Role"
          />
          <DeleteOutlineTwoToneIcon
            className="cursor-pointer"
            onClick={() => openDeleteModel(originalRow)}
            titleAccess="Delete Role"
          />
        </React.Fragment>
      ),
    },
  ];

  const columns = useMemo(() => columnsData, [editRowAction, deleteRowAction]);
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(roleList(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ''}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            Roles
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/role/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={rolesData}
            fetchData={fetchData}
            totalRecords={totalRoles}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
      <Grid>
      <Dialog
          open={modalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Remove Blog</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this role?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteRowAction(currentRole)}>
              Yes
            </Button>
            <Button onClick={() => updateModalState()}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}

export default RolesList;
