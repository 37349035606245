
import { configEnv } from '../configEnv';
import getToken from '../token';
const envConfig = configEnv();
async function login(postData) {
  let url = `${envConfig}auth/login`;
  try {
    let customHeaders = apiHeaders();
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: customHeaders,
      body: JSON.stringify(postData),
    });
    return response.json();
  } catch (error) {
    return CatchError(error);
  }
}

async function http_get(
  api_url,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  let token = getToken();
  let url = `${envConfig}` + api_url;
  try {
    return await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

async function http_post(
  api_url,
  postData,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  let token = getToken();
  let url = `${envConfig}` + api_url;
  try {
    return await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

async function http_formdata_post(
  api_url,
  postData,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  let token = getToken();
  let url = `${envConfig}` + api_url;
  try {
    return await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: postData,
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

async function http_formdata_patch(
  api_url,
  postData,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  let token = getToken();
  let url = `${envConfig}` + api_url;
  try {
    return await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: postData,
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

async function http_patch(
  api_url,
  postData,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  try {
    let token = getToken();
    let url = `${envConfig}` + api_url;
    return await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

async function http_delete(
  api_url,
  contentType = 'json',
  acceptType = 'json',
  isAuthenticated = true
) {
  let token = getToken();
  let url = `${envConfig}` + api_url;
  try {
    return await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          removeToken();
        }
        return acceptType === 'json' ? response.json() : response;
      })
      .catch((error) => {
        throw new Error(error);
      });
  } catch (error) {
    return CatchError(error);
  }
}

function removeToken() {
  localStorage.removeItem('user-credentials');
  window.location.reload();
}
function CatchError(err) {
  let errorMsg = {};
  if (err instanceof TypeError) {
    errorMsg.message =
      err !== undefined && err.message !== ''
        ? err.message
        : 'Something went wrong';
    // statements to handle TypeError exceptions
  } else if (err instanceof RangeError) {
    errorMsg.message =
      err !== undefined && err.message !== ''
        ? err.message
        : 'Something went wrong';
    // statements to handle RangeError exceptions
  } else if (err instanceof EvalError) {
    errorMsg.message =
      err !== undefined && err.message !== ''
        ? err.message
        : 'Something went wrong';
    // statements to handle EvalError exceptions
  } else {
    errorMsg.message =
      err !== undefined && err.message !== ''
        ? err.message
        : 'Something went wrong';
  }
  return Promise.reject(errorMsg);
}

function apiHeaders(contentType = 'json', isAuthenticated = false) {
  let token = getToken();
  let customHeaders = new Headers();
  switch (contentType) {
    case 'json':
      customHeaders.append('Content-Type', 'application/json');
      break;

    case 'url-encoded':
      customHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      break;

    case 'form-data':
      customHeaders.append('Content-Type', 'multipart/form-data');
      break;

    default:
      break;
  }

  if (isAuthenticated && Object.keys(customHeaders).length > 0) {
    if (token !== false) {
      customHeaders.append('Authorization', 'Bearer ' + token);
    } else {
      return Promise.reject('Invalid user');
    }
  }
  return customHeaders;
}
export const HTTPClient = {
  login,
  http_get,
  http_post,
  http_patch,
  http_delete,
  http_formdata_post,
  http_formdata_patch,
};
