/* eslint-disable react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  Grid,
  Typography,
  Button,
  EditTwoToneIcon,
  //DeleteOutlineTwoToneIcon,
} from '../../../utils/components/index';
import { Link, useNavigate } from 'react-router-dom';
import { languageList, clearState } from './languageSlice';
import ReactTable from '../../../utils/react-table/ReactTable';
import { languageSelector } from './languageSlice';
import Loader from '../../../utils/components/loader';

function LanguagesList() {
  let navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const languagesData = useSelector((state) => state.languages.languages);
  let totalLanguages = useSelector((state) => state.languages.totalLanguages);

  const { isFetching } = useSelector(languageSelector);

  const totalPages = useSelector((state) => {
    return state.languages.totalLanguages > 0
      ? Math.ceil(state.languages.totalLanguages / pageSize)
      : 0;
  });

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/language/edit/${data._id}`);
  }

  // function deleteRowAction(data) {
  //   dispatch(clearState());
  //   dispatch(deleteLanguage(data._id));
  // }

  const columnsData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Code',
      accessor: 'code',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <EditTwoToneIcon
            className="cursor-pointer"
            onClick={() => editRowAction(originalRow)}
            titleAccess="Edit Language"
          />
          {/* <DeleteOutlineTwoToneIcon
            className="cursor-pointer"
            onClick={() => deleteRowAction(originalRow)}
          /> */}
        </React.Fragment>
      ),
    },
  ];

  const dispatch = useDispatch();
  const columns = useMemo(() => columnsData, [editRowAction]);
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(languageList(paginationData)))
      .then(() => dispatch(clearState()))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
      {isFetching ? <Loader /> : ''}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            Languages
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/language/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={languagesData}
            fetchData={fetchData}
            totalRecords={totalLanguages}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default LanguagesList;
