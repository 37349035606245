import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { is, objectTraps } from "immer/dist/internal";
import { HTTPClient } from '../../../utils/services/http.services';

export const addSeo = createAsyncThunk(
  'seo/add', // frontend url
  async (postData, { rejectWithValue }) => {
    try {
      return await HTTPClient.http_post(
        'seo/store', // backend url
        postData
      ).then((resp) => {
        if (resp.data && resp.statusCode === 201) {
          return resp;
        } else {
          throw new Error(resp.message);
        }
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const seoList = createAsyncThunk(
  'seo',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `seo`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `seo?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp.message);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const editSeo = createAsyncThunk(
  'seo/edit', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let id = postData.id;
      delete postData[id];
      let editSeo = await HTTPClient.http_patch('seo/' + id, postData); // this is from backend url
      return editSeo;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteSeo = createAsyncThunk(
  'seo/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteSeo = await HTTPClient.http_delete(`seo/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp.message);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteSeo;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  seos: [],
  totalSeo: 0,
};

export const seoSlice = createSlice({
  name: 'seos',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
  },
  extraReducers: {
    [addSeo.pending]: (state) => {
      state.isFetching = true;
    },
    [addSeo.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.seos.push(payload.data);
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [addSeo.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [seoList.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [seoList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.seos = payload.data;
        state.totalSeo = payload.total;
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [seoList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editSeo.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [editSeo.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.seos.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [editSeo.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [deleteSeo.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteSeo.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.seos = state.seos.filter((seo) => seo._id !== payload.removeId);
        state.totalSeo = state.totalSeo - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteSeo.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = seoSlice.actions;
export const seoSelector = (state) => state.seos;
