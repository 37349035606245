import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addBlog = createAsyncThunk(
  'blog/add', //  this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let addBlog = await HTTPClient.http_formdata_post(
        'blogs/store',
        postData
      ); // this is from backend url, linkup the frontend url with backend url here.
      return addBlog;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addBlogEditorFile = createAsyncThunk(
  'blog/editor-file-upload', //  this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let fileUpload = await HTTPClient.http_formdata_post(
        'blogs/editor-file-upload',
        postData
      ); // this is from backend url, linkup the frontend url with backend url here.
      if (fileUpload.statusCode === 200) {
        return fileUpload;
      } else {
        return false;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const blogList = createAsyncThunk(
  'blog/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `blogs`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `blogs?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          // url = url + `&search=${searchValue}`;
          url = url + `&isActive=1&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const previewBlog = createAsyncThunk(
  'blog/preview', //  this is frontend url, this is a action which will show is redux actions
  async (id, { thunkAPI }) => {
    try {
      const viewBlog = await HTTPClient.http_get(`blogs/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return viewBlog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editBlog = createAsyncThunk(
  'blog/edit', //  this is frontend url, this is a action which will show is redux actions
  async (id, { thunkAPI }) => {
    try {
      const edit = await HTTPClient.http_get(`blogs/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return edit;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateBlog = createAsyncThunk(
  'blog/update', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let id;
      for (let [key, value] of postData.entries()) {
        if (key === 'id') {
          id = value;
        }
      }

      if (id !== undefined) {
        let update = await HTTPClient.http_formdata_patch(
          'blogs/' + id,
          postData
        ); // this is from backend url
        return update;
      } else {
        throw new Error('Invalid data');
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBlog = createAsyncThunk( // permanently delete from trash list
  'blog/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteBlog = await HTTPClient.http_delete(`blogs/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteBlog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const moveBlogToTrash = createAsyncThunk( // delete and move to trash list
  'blog/trash',
  async (id, { thunkAPI }) => {
    try {
      const deleteBlog = await HTTPClient.http_get(`blogs/trash/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteBlog;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const listOfDeletedBlogs = createAsyncThunk( // list of deleted blogs from blog list
  'trash/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `blogs/get-inactive-blogs`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `blogs/get-inactive-blogs?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&isActive=0&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const initialState = {
  blogs: [],
  totalBlogs: 0,
};

export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
  },
  extraReducers: {
    [addBlog.pending]: (state) => {
      state.isFetching = true;
    },
    [addBlog.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.isSuccess = true;
        state.blogs.push(payload.data);
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [addBlog.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [blogList.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [blogList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs =
          Array.isArray(payload.data) && payload.data.length > 0
            ? payload.data
            : [];
        state.totalBlogs = payload.total;
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
        return state;
      } else {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [blogList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMgs =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editBlog.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [editBlog.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs = state.blogs.filter((blog) => {
          return blog._id
        });
        // state.blogs.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [editBlog.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [updateBlog.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [updateBlog.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs.push(payload.data);
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [updateBlog.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [deleteBlog.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [deleteBlog.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs = state.blogs.filter(
          (blog) => blog._id !== payload.removeId
        );
        state.totalBlogs = state.totalBlogs - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteBlog.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [moveBlogToTrash.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [moveBlogToTrash.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs = state.blogs.filter(
          (blog) => blog._id !== payload.removeId
        );
        state.totalBlogs = state.totalBlogs - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.isFetching = false;
        state.isSuccess = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [moveBlogToTrash.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.isSuccess = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
    [listOfDeletedBlogs.pending]: (state) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    [listOfDeletedBlogs.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs =
          Array.isArray(payload.data) && payload.data.length > 0
            ? payload.data
            : [];
        state.totalBlogs = payload.total;
        state.isSuccess = true;
        state.isFetching = false;
        state.isError = false;
        return state;
      } else {
        state.isError = true;
        state.isSuccess = false;
        state.isFetching = false;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [listOfDeletedBlogs.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMgs =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [previewBlog.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.isSuccess = false;
    },
    [previewBlog.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.blogs = state.blogs.filter((blog) => {
          return blog._id
        });
        // state.blogs.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [previewBlog.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isSuccess = false;
      state.isFetching = false;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = blogSlice.actions;
export const blogSelector = (state) => state.blogs;

// import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
// import { sub } from 'date-fns';
// import axios from "axios";

// const initialState = {
//   blogs: [],
//   status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
//   error: null
// }

// export const fetchBlogs = createAsyncThunk('posts/fetchPosts', async () => {
//     const response = await axios.get(POSTS_URL)
//     return response.data
// })

// export const addBlogs = createAsyncThunk('blog/add', async (postData) => {
//   const response = await axios.postHTTPClient.http_formdata_post(
//     'blogs/store',
//     postData
//   );
//   return response.data
// })

// const blogsSlice = createSlice({
//   name: 'blogs',
//   initialState,
//   reducers: {
//     postAdded: {
//       reducer(state, action) {
//         state.blogs.push(action.payload)
//       },
//       prepare(title, content, userId) {
//         return {
//           payload: {
//             id: nanoid(),
//             title,
//             content,
//             date: new Date().toISOString(),
//             userId,
//           }
//         }
//       }
//     },
//   },
//   extraReducers(builder) {
//     builder
//       // .addCase(fetchPosts.pending, (state, action) => {
//       //   state.status = 'loading'
//       // })
//       // .addCase(fetchPosts.fulfilled, (state, action) => {
//       //   state.status = 'succeeded'
//       //   // Adding date and reactions
//       //   let min = 1;
//       //   const loadedPosts = action.payload.map(post => {
//       //     post.date = sub(new Date(), { minutes: min++ }).toISOString();
//       //     post.reactions = {
//       //       thumbsUp: 0,
//       //       wow: 0,
//       //       heart: 0,
//       //       rocket: 0,
//       //       coffee: 0
//       //     }
//       //     return post;
//       //   });

//       //   // Add any fetched posts to the array
//       //   state.posts = state.posts.concat(loadedPosts)
//       // })
//       // .addCase(fetchPosts.rejected, (state, action) => {
//       //   state.status = 'failed'
//       //   state.error = action.error.message
//       // })
//       .addCase(addBlogs.fulfilled, (state, action) => {
//         // Fix for API post IDs:
//         // Creating sortedPosts & assigning the id
//         // would be not be needed if the fake API
//         // returned accurate new post IDs
//         const sortedPosts = state.posts.sort((a, b) => {
//           if (a.id > b.id) return 1
//           if (a.id < b.id) return -1
//           return 0
//         })
//         action.payload.id = sortedPosts[sortedPosts.length - 1].id + 1;
//         // End fix for fake API post IDs

//         action.payload.userId = Number(action.payload.userId)
//         action.payload.date = new Date().toISOString();
//         console.log(action.payload)
//         state.blogs.push(action.payload)
//       })
//   }
// })

// export const selectAllBlogs = (state) => state.blogs.blogs;
// export const getBlogsError = (state) => state.blogs.error;

// export const { blogAdded } = blogsSlice.actions

// export default blogsSlice.reducer
