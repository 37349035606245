/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { seoList, clearState, deleteSeo } from './seoSlice';
import { Link, useNavigate } from 'react-router-dom';
import ReactTable from '../../../utils/react-table/ReactTable';
import { seoSelector } from './seoSlice';

import {
  Add,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  EditTwoToneIcon,
  DeleteOutlineTwoToneIcon,
} from '../../../utils/components/index';
import Loader from '../../../utils/components/loader';

function SeoList() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const seosData = useSelector((state) => state.seos.seos);

  const { isFetching } = useSelector(seoSelector);

  let totalSeo = useSelector((state) => state.seos.totalSeo);
  totalSeo = totalSeo !== undefined && totalSeo > 0 ? totalSeo : 0;
  const totalPages = useSelector((state) => {
    return state.seos.totalSeo > 0
      ? Math.ceil(state.seos.totalSeo / pageSize)
      : 0;
  });

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/seo/edit/${data._id}`);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [currentSEO, setCurrentSEO] = useState(undefined);

  function openDeleteModel(data) {
    setCurrentSEO(data._id);
    updateModalState();
  }

  function updateModalState() {
    setModalOpen(!modalOpen);
  }

  function deleteRowAction(data) {
    if (data) {
      dispatch(deleteSeo(data));
      updateModalState();
      dispatch(clearState());
    }
  }

  const columnsData = [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Domain',
      accessor: 'domain_name',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <EditTwoToneIcon
            className="cursor-pointer"
            onClick={() => editRowAction(originalRow)}
            titleAccess="Edit SEO"
          />
          <DeleteOutlineTwoToneIcon
            className="cursor-pointer"
            onClick={() => openDeleteModel(originalRow)}
            titleAccess="Delete SEO"
          />
        </React.Fragment>
      ),
    },
  ];

  const columns = useMemo(() => columnsData, [editRowAction, deleteRowAction]);
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(seoList(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ''}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            SEO
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/seo/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            data={seosData}
            columns={columns}
            fetchData={fetchData}
            totalRecords={totalSeo}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
      <Grid>
      <Dialog
          open={modalOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete this SEO?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteRowAction(currentSEO)}>
              Yes
            </Button>
            <Button onClick={() => updateModalState()}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}

export default SeoList;
