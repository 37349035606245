/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tagSelector, clearState, addTag } from "./tagSlice";
import { useNavigate } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "../../../utils/components/index";



const AddTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // meta description
const [metaDescriptionText, setMetaDescriptionText] = useState("");
const metaDescriptionChangeHandler = (event) => {
  setMetaDescriptionText(event.target.value);
};

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(tagSelector);

  const onSubmit = (data) => {
    let postData = {
      name: data.name,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription
    };
    dispatch(deleteAlert());
    dispatch(clearState());
    dispatch(addTag(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/tag/list");
      }, 1200);
    } else if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Tag
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              id="tag-name"
              required
              fullWidth
              autoComplete="off"
              label="Tag Name"
              autoFocus
              inputProps={register("name", validations.tag)}
              error={errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="metaTitle"
              required
              fullWidth
              label="Meta Title"
              placeholder="Maximum 60 characters"
              type="text"
              id="seoTitle"
              inputProps={register("metaTitle", validations.seo_title)}
              error={errors.metaTitle}
              helperText={errors.metaTitle?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="metaDescription"
              id="meta-description"
              required
              fullWidth
              autoComplete="off"
              label="Meta Description"
              placeholder="Maximum 160 characters with space"
              multiline
              rows={1}
              value={metaDescriptionText}
              onChange={metaDescriptionChangeHandler}
              inputProps={register(
                "metaDescription",
                validations.meta_description
              )}
              error={errors.metaDescription}
              helperText={errors.metaDescription?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("metaDescription")?.length}
              </p>
            </span>
          </Grid>
        </Grid>
          
        <Button
          type="submit"
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : "Add"}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default AddTag;
