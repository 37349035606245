import React from 'react';
import { Stack, LinearProgress } from './index';
function Loader() {
  return (
    <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <LinearProgress color="inherit" />
    </Stack>
  );
}

export default Loader;
