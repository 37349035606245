import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './routes/routeConfig';
import { useDispatch } from 'react-redux';
import { reset } from './components/outside-login/login/authSlice';

import {
  Box,
  ThemeProvider
} from '../src/utils/components/index';
import themeConfig from './themes/theme';

function App() {
  const dispatch = useDispatch();
  let isLoggedIn =
    localStorage.getItem('user-credentials') !== null ? true : false;
  if (isLoggedIn === null) {
    dispatch(reset());
  }
  let routing = useRoutes(routes(isLoggedIn));
  return (
    <ThemeProvider theme={themeConfig}>
      <Box
        style={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        {routing}
      </Box>
    </ThemeProvider>
  );
}

export default App;
