/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
// import { languageList } from '../languages/languageSlice';
// import { domainList } from '../domains/domainSlice';
// import { blogList } from '../blogs/blogSlice';
// import { categoryList, clearState } from '../category/categorySlice';
import { verifyUser } from '../../outside-login/login/authSlice';
import { useSelector, useDispatch } from 'react-redux';
function Dashboard() {
  const dispatch = useDispatch();
  // const authUser = useSelector((state) => state.auth);
  // console.log('authUser ', authUser);
  // const blogData = useSelector((state) => state.blogs.blogs);
  // const domainData = useSelector((state) => state.domains.domains);
  // const languageData = useSelector((state) => state.languages.languages);
  // const categoriesData = useSelector((state) => state.categories.categories);
  useEffect(() => {
    // dispatch(clearState());
    dispatch(verifyUser());
    // if (Object.keys(languageData).length === 0 || languageData.length <= 0) {
    //   dispatch(languageList());
    // }
    // if (Object.keys(domainData).length === 0 || domainData.length <= 0) {
    //   dispatch(domainList());
    // }
    // if (
    //   Object.keys(categoriesData).length === 0 ||
    //   categoriesData.length <= 0
    // ) {
    //   dispatch(categoryList());
    // }
    // if (Object.keys(blogData).length === 0 || blogData.length <= 0) {
    //   dispatch(blogList());
    // }
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div>Dashboard</div>;
}

export default Dashboard;
