import { configureStore } from '@reduxjs/toolkit';
import { alertSlice } from '../../components/alert/alertSlice';
import { blogSlice } from '../../components/inside-login/blogs/blogSlice';
import { categorySlice } from '../../components/inside-login/category/categorySlice';
import { domainSlice } from '../../components/inside-login/domains/domainSlice';
import { languageSlice } from '../../components/inside-login/languages/languageSlice';
import { roleSlice } from '../../components/inside-login/roles/roleSlice';
import { seoSlice } from '../../components/inside-login/seo/seoSlice';
import { authSlice } from '../../components/outside-login/login/authSlice';
import { tagSlice } from '../../components/inside-login/tags/tagSlice';

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    domains: domainSlice.reducer,
    roles: roleSlice.reducer,
    languages: languageSlice.reducer,
    categories: categorySlice.reducer,
    seos: seoSlice.reducer,
    blogs: blogSlice.reducer,
    tags: tagSlice.reducer,
    notifications: alertSlice.reducer,
  },
});
