/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import validations from "../../../utils/validations/validation.rules";
import { addRole, roleSelector, clearState } from "./roleSlice";
import { useNavigate } from "react-router-dom";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from "../../../utils/components/index";

function AddRole() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isSuccess, isError, responseMsg } = useSelector(roleSelector);

  const onSubmit = (data) => {
    let postData = { name: data.role_name, code: data.roleCode };
    dispatch(clearState());
    dispatch(addRole(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/role/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Role
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="role name"
              required
              fullWidth
              id="role name"
              label="Role Name"
              autoFocus
              inputProps={register("role_name", validations.role_name)}
              error={errors.role_name}
              helperText={errors.role_name?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="roleCode"
              label="Role Code"
              name="roleCode"
              autoComplete="off"
              inputProps={register("roleCode", validations.role_code)}
              error={errors.role_code}
              helperText={errors.role_code?.message}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          startIcon={<Add />}
          sx={{ mt: 3, mb: 2 }}
        >
          Add
          {/* {isFetching ? <CircularProgress color="secondary" /> : "Add"}  */}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default AddRole;
