import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tagSelector, clearState, editTag } from "./tagSlice";
import { useNavigate, useParams } from "react-router-dom";
import validations from "../../../utils/validations/validation.rules";
import { useForm } from "react-hook-form";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "../../../utils/components/index";

const EditTag = () => {
  const { id } = useParams();
  const tagData = useSelector((state) => state.tags.tags);
  const data = tagData.filter((data) => {
    return data._id === id;
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });

  // const languageData = useSelector((state) => state.languages.languages);
  // const [language, setLanguage] = useState(data[0]?.language);

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(tagSelector);

  // meta description
  const [metaDescriptionText, setMetaDescriptionText] = useState("");
  const metaDescriptionChangeHandler = (event) => {
    setMetaDescriptionText(event.target.value);
  };

  const onSubmit = (data) => {
    let postData = {
      // language: data.language,
      name: data.name,
      // code: data.code,
      id: id,
      isActive: data.isActive,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription
    };
    dispatch(editTag(postData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/tag/list");
      }, 3000);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 3000);
    }
  }, [isSuccess, isError, responseMsg]);

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit Tag
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              id="name"
              required
              fullWidth
              autoComplete="off"
              label="Tag Name"
              inputProps={register("name", validations.tag)}
              error={errors.tag}
              helperText={errors.tag?.message}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="metaTitle"
              required
              fullWidth
              label="Meta Title"
              placeholder="Maximum 60 characters"
              type="text"
              id="seoTitle"
              inputProps={register("metaTitle", validations.seo_title)}
              error={errors.metaTitle}
              helperText={errors.metaTitle?.message}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="metaDescription"
              id="meta-description"
              required
              fullWidth
              autoComplete="off"
              label="Meta Description"
              placeholder="Maximum 160 characters with space"
              multiline
              rows={1}
              value={data[0]?.metaDescriptionText}
              onChange={metaDescriptionChangeHandler}
              inputProps={register(
                "metaDescription",
                validations.meta_description
              )}
              error={errors.metaDescription}
              helperText={errors.metaDescription?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("metaDescription")?.length}
              </p>
            </span>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl
              className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl css-md26zr-MuiInputBase-root-MuiOutlinedInput-root"
              sx={{ width: "100%" }}
            >
              <InputLabel id="status-label">Status *</InputLabel>
              <Select
                fullWidth
                required
                name="isActive"
                id="status"
                defaultValue={data[0]?.isActive}
                label="Status *"
                // onChange={handleBlogStatusType}
                error={errors.status}
                inputProps={register("isActive", validations.status)}
              >
                <MenuItem key="ac" value="true">
                  Active
                </MenuItem>
                <MenuItem key="inac" value="false">
                  Inactive
                </MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              name="code"
              id="code"
              required
              fullWidth
              autoComplete="off"
              label="Code"
              inputProps={register("code", validations.category_code)}
              error={errors.code}
              helperText={errors.code?.message}
            />
          </Grid> */}
        </Grid>
        <Button
          type="submit"
          disabled={isFetching ? true : false}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {isFetching ? <CircularProgress color="secondary" /> : "Update"}
        </Button>
      </Box>
    </React.Fragment>
  );
} 

export default EditTag;