import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  Button,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  FormControl,
  NativeSelect,
  TableContainer,
  ArrowBackIosTwoToneIcon,
  ArrowForwardIosTwoToneIcon,
  KeyboardDoubleArrowLeftTwoToneIcon,
  KeyboardDoubleArrowRightTwoToneIcon,
} from '../../utils/components/index';

import { makeStyles } from '@mui/styles';

import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { GlobalFilter } from './GlobalFilter';

const useStyles = makeStyles((theme) => ({
  table_head: {
    backgroundColor: theme.palette.primary.main,
  },
  table_head_cell: {
    fontWeight: 550,
    color: 'white',
  },
  pagination_left: {
    textAlign: 'left',
    float: 'left',
    cursor: 'pointer',
  },
  pagination_right: {
    display: 'flex',
    justifyContent: 'right',
    cursor: 'pointer',
  },
}));

function ReactTable({
  columns,
  data,
  fetchData,
  totalRecords,
  totalPages: controlledPageCount,
}) {
  const classes = useStyles();
  const [pageSearch, setPageSearch] = useState('');
  const {
    page,
    gotoPage,
    nextPage,
    pageCount,
    prepareRow,
    canNextPage,
    setPageSize,
    headerGroups,
    previousPage,
    getTableProps,
    canPreviousPage,
    getTableBodyProps,
    // Get the state from the instance
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSearch: '',
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      pageCount: controlledPageCount,
    },
    useGlobalFilter,
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData(pageIndex, pageSearch, pageSize);
  }, [fetchData, pageIndex, pageSize, pageSearch]);
  // getData
  const getData = (data) => {
    setPageSearch(data);
  };
  // Render the UI for your table
  return (
    <React.Fragment>
      <Box>
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          getData={getData}
        />
        <TableContainer component={Paper}>
          <Table {...getTableProps()} aria-label="simple table">
            <TableHead className={classes.table_head}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      className={classes.table_head_cell}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container spacing={0} mt={1}>
          <Grid item xs={6} className={classes.pagination_left}>
            <span>
              <i>
                Showing{' '}
                {pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1} to{' '}
                {(pageIndex + 1) * pageSize > totalRecords
                  ? totalRecords
                  : pageIndex + 1 * pageSize}
                {' of '}
                {totalRecords}
                {' enteries'}
              </i>
            </span>
          </Grid>
          <Grid item xs={6} className={classes.pagination_right}>
            <Box sx={{ minWidth: 75 }}>
              <FormControl>
                <NativeSelect
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                  label="Per Page"
                  inputProps={{
                    name: 'perpage',
                    id: 'uncontrolled-native',
                  }}
                >
                  {[2, 5, 10, 20, 30, 40, 50].map((ps) => (
                    <option key={ps} value={ps}>
                      {ps}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Box>
            <Button
              variant="outlined"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <KeyboardDoubleArrowLeftTwoToneIcon fontSize="small" />{' '}
            </Button>
            <Button
              variant="outlined"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <ArrowBackIosTwoToneIcon fontSize="small" />{' '}
            </Button>
            <Button
              variant="outlined"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <ArrowForwardIosTwoToneIcon fontSize="small" />{' '}
            </Button>
            <Button
              variant="outlined"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <KeyboardDoubleArrowRightTwoToneIcon fontSize="small" />{' '}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default ReactTable;
