import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = (props) => {
  const editorRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(props?.initialValue);
  return (
    <>
      <Editor
        apiKey="3h32lvzcgus03b4qga0vgsxt1t7yfhhzhpauizjzapcsozfr"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        initialValue={value}
        init={{
          height: 300,
          menubar: true,
          // browser_spellcheck: true,
          /* enable title field in the Image dialog*/
          image_title: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code",
            "insertdatetime media table paste image code help wordcount",
          ],
          font_formats:
            "Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; AkrutiKndPadmini=Akpdmi-n",
          toolbar:
            "undo redo | htmlFor=matselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removehtmlFor=mat | help",
          init_instance_callback: function (editor) {
            editor.on("keyDown", function (e) {
              if ((e.keyCode === 8 || e.keyCode === 46) && editor.selection) {
                // delete & backspace keys
                var selectedNode = editor.selection.getNode(); // get the selected node (element) in the editor
                if (selectedNode && selectedNode.nodeName === "IMG") {
                  let imgsrc = document
                    .querySelector("img")
                    .getAttribute("src");
                  if (imgsrc) {
                    props.removeBlogFile(imgsrc);
                  }
                }
              }
            });
          },
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
              var file = this.files[0];

              var reader = new FileReader();
              reader.onload = function () {
                const fileInfo = new Promise((resolve, reject) => {
                  let fileResult = props.fileUploadHandler(file);
                  resolve(fileResult);
                });
                fileInfo.then((resp) => {
                  let filelocation = resp.location;
                  cb(filelocation, { title: file.name });
                });
              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px, margin-top: 20px }",
        }}
        onEditorChange={props.editorData}
      />
    </>
  );
};

export default TextEditor;
