import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table/dist/react-table.development';

import {
  Paper,
  InputBase,
  IconButton,
  SearchIcon,
  Grid,
} from '../components/index';

export const GlobalFilter = ({ filter, setFilter, getData }) => {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1200); // search at a delay of 1000ms

  return (
    <Grid container m={1}>
      <Grid item xs={12} pr={1}>
        <Paper
          component="form"
          sx={{
            mb: '1px',
            display: 'flex',
            float: 'right',
            justifyContent: 'flex-end',
            border: '1px solid grey',
            width: 300,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search ..."
            inputProps={{ 'aria-label': 'search roles' }}
            value={value || ''}
            onChange={(e) => {
              getData(e.target.value);
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
          <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  );
};
