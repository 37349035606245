/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import validations from "../../../utils/validations/validation.rules";
import {
  addBlog,
  blogSelector,
  clearState,
  addBlogEditorFile,
} from "./blogSlice";
import { activeDomainList } from "../domains/domainSlice";
import { activeCategoryList } from "../category/categorySlice";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../Editor/TextEditor";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Add,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Stack,
  Chip,
  // FormControl,
  // FormHelperText,
  // OutlinedInput,
  // Select,
  // InputLabel,
} from "../../../utils/components/index";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
    dispatch(activeDomainList());
    dispatch(activeCategoryList());
    // if (Object.keys(domainData).length === 0 || domainData.length <= 0) {

    // }
    // if (
    //   Object.keys(categoriesData).length === 0 ||
    //   categoriesData.length <= 0
    // ) {

    // }
    // if (Object.keys(blogData).length === 0 || blogData.length <= 0) {  //this is for set related links from blogData
    //   dispatch(blogList());
    // }
    dispatch(clearState());
  }, []);

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(blogSelector);

  const domainData = useSelector((state) => state.domains.domains);
  const categoriesData = useSelector((state) => state.categories.categories);

  // text editor
  const [textEditor, setTextEditor] = useState(null);
  const handleEditorChange = (editorRef) => {
    if (editorRef !== undefined) {
      setTextEditor(editorRef);
      setValue("description", editorRef);
    }
  };

  // img and file upload in text editor
  const fileUploadHandler = (file) => {
    let formData = new FormData();
    formData.append("editorFile", file);
    dispatch(clearState());
    return dispatch(addBlogEditorFile(formData)).then((resp) => {
      if (resp.payload?.statusCode === 200 && resp.payload?.data?.location) {
        return { location: resp.payload.data.location };
      } else {
        dispatch(
          createAlert({
            message:
              resp.payload.message !== undefined
                ? resp.payload.message
                : "Something went wrong. Try again later.",
            type: "error",
          })
        );
      }
    });
  };

  // related tags
  const [keywords, setkeywords] = useState({
    items: [],
    value: "",
  });

  const handleRelatedTagKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let value = evt.target.value.trim();
      if (value) {
        setkeywords({
          items: [...keywords.items, evt.target.value],
          value: "",
        });
      }
    }
  };

  const handleRelatedTagChange = (evt) => {
    setkeywords({
      items: [...keywords.items],
      value: evt.target.value,
    });
  };

  const handleRelatedTagDelete = (item) => {
    let newData = keywords.items.filter((i) => i !== item);
    setkeywords({ items: newData, value: keywords.value });
  };

  // meta description
  const [metaDescriptionText, setMetaDescriptionText] = useState("");
  const metaDescriptionChangeHandler = (event) => {
    setMetaDescriptionText(event.target.value);
  };

  // short description
  const [shortDescriptionText, setShortDescriptionText] = useState("");
  const shortDescriptionChangeHandler = (event) => {
    setShortDescriptionText(event.target.value);
  };

  // blog author
  // const [author, setAuthor] = useState([]);
  // const handleAuthor = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setAuthor(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.textEditor = textEditor;
    data.keywords = keywords;
    let formData = new FormData();
    formData.append("domainId", data.domainId); // all left side like domainId is backend key
    formData.append("categoryId", data.categoryId);
    formData.append("author", data.author);
    formData.append("thumbnailImg", data.thumbnailImg[0]);
    formData.append("bannerImg", data.bannerImg[0]);
    formData.append("imgAltTag", data.imgAltTag);
    formData.append("title", data.title);
    formData.append("slug", data.slug);
    formData.append("metaTitle", data.metaTitle);
    formData.append("canonicalUrl", data.canonicalUrl);
    formData.append("metaDescription", data.metaDescription);
    formData.append("shortDesc", data.shortDesc);
    formData.append("description", data.textEditor);
    formData.append("relatedTags[]", keywords.items);
    dispatch(addBlog(formData));
  };

  // domain change with title, slug, canonical url
  const [currentDomainUrl, setCurrentDomainUrl] = useState("");

  function onDomainChange() {
    // if use e in parameter -> e.target.dataset.url or else this['data-url']; dataset is inbuild key of e.target
    let url = this["data-url"];
    let slug = getValues("slug");
    if (url && slug === "") {
      setValue("canonicalUrl", url);
    }
    if (url && slug) {
      let canonical_url = url + "/" + slug;
      setValue("canonicalUrl", canonical_url);
    }
    setCurrentDomainUrl(url);
  }

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
        navigate("/blog/list");
      }, 2500);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isSuccess, isError, responseMsg]);

  // this is for related links(collections of all blog from the list)
  // const blogData = useSelector((state) => state.blogs.blogs);
  // const [relatedLinks, setRelatedLinks] = useState([]);
  // const handleRelatedTabs = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRelatedTabs(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Add Blog
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue=""
              label="Domain *"
              name="domainId"
              inputProps={register("domainId", validations.domain_id)}
              error={errors.domainId}
              helperText={errors.domainId?.message}
            >
              {domainData.map((domain) => (
                <MenuItem
                  key={domain.id}
                  value={domain.id}
                  data-url={domain.url}
                  onClick={onDomainChange}
                >
                  {domain.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue=""
              name="categoryId"
              label="Blog Category *"
              inputProps={register("categoryId", validations.category_id)}
              error={errors.categoryId}
              helperText={errors.categoryId?.message}
            >
              {categoriesData.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="author-id">Blog Author</InputLabel>
              <Select
                labelId="author-id"
                id="author"
                required
                fullWidth
                multiple
                value={author}
                onChange={handleAuthor}
                input={<OutlinedInput label="Blog Author *" />}
                inputProps={register("author", validations.blog_author)}
                error={errors.author}
                // helperText={errors.author?.message}
              >
                <MenuItem value="arghya">Arghya</MenuItem>
                <MenuItem value="sharanya">Sharanya</MenuItem>
                <MenuItem value="gopal">Gopal</MenuItem>
                <MenuItem value="atreyee">Atreyee</MenuItem>
              </Select>

              <FormHelperText style={{ color: "red" }}>
                {errors.author?.message}
              </FormHelperText>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="author"
              label="Blog Author"
              name="author"
              autoComplete="off"
              inputProps={register("author", validations.blog_author)}
              error={errors.author}
              helperText={errors.author?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              focused
              autoComplete="off"
              name="thumbnailImg"
              required
              fullWidth
              label="Thumbnail Image (Preferable size 762x400)"
              type="file"
              id="thumbnailImg"
              accept="image/*"
              inputProps={register(
                "thumbnailImg",
                validations.small_banner_image
              )}
              error={errors.thumbnailImg}
              helperText={errors.thumbnailImg?.message}
            />
            {/* <small style={{ color: 'green' }}>
              Size should be 762x400
            </small> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              focused
              autoComplete="off"
              name="bannerImg"
              required
              fullWidth
              label="Banner Image (Preferable size 1920x540)"
              type="file"
              id="bannerImg"
              accept="image/*"
              inputProps={register("bannerImg", validations.banner_image)}
              error={errors.bannerImg}
              helperText={errors.bannerImg?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="imgAltTag"
              required
              fullWidth
              id="imgAltTag"
              label="Banner Image Alt Tag"
              inputProps={register(
                "imgAltTag",
                validations.banner_image_alt_tag
              )}
              error={errors.imgAltTag}
              helperText={errors.imgAltTag?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="title"
              required
              fullWidth
              label="Title"
              type="text"
              id="title"
              inputProps={register("title", validations.title)}
              onChange={(e) => {
                let values = e.target.value;
                if (values) {
                  values = values.toLowerCase();
                  values = values.replace(/[^a-zA-Z0-9]/g, "-");
                  values = values.replace(/-+/g, "-");
                  setValue("slug", values);
                  if (currentDomainUrl !== "") {
                    setValue("canonicalUrl", currentDomainUrl + "/" + values);
                  }
                } else {
                  setValue("slug", "");
                }
              }}
              error={errors.title}
              helperText={errors.title?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              focused
              autoComplete="off"
              name="slug"
              required
              fullWidth
              label="Slug"
              type="text"
              id="slug"
              inputProps={register("slug", validations.slug)}
              onChange={(e) => {
                let values = e.target.value;
                if (values) {
                  values = values.toLowerCase();
                  values = values.replace(/[^a-zA-Z0-9]/g, "-");
                  values = values.replace(/-+/g, "-");
                  setValue("slug", values);
                  if (currentDomainUrl !== "") {
                    setValue("canonicalUrl", currentDomainUrl + "/" + values);
                  }
                } else {
                  setValue("slug", "");
                }
              }}
              error={errors.slug}
              helperText={errors.slug?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="metaTitle"
              required
              fullWidth
              label="Meta Title"
              placeholder="Maximum 60 characters"
              type="text"
              id="seoTitle"
              inputProps={register("metaTitle", validations.seo_title)}
              error={errors.metaTitle}
              helperText={errors.metaTitle?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="canonicalUrl"
              required
              focused
              fullWidth
              label="Canonical URL"
              type="text"
              id="canonicalUrl"
              inputProps={register("canonicalUrl", validations.canonical)}
              error={errors?.canonicalUrl}
              helperText={errors.canonicalUrl?.message}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              name="metaDescription"
              id="meta-description"
              required
              fullWidth
              autoComplete="off"
              label="Meta Description"
              placeholder="Maximum 160 characters with space"
              multiline
              rows={1}
              value={metaDescriptionText}
              onChange={metaDescriptionChangeHandler}
              inputProps={register(
                "metaDescription",
                validations.meta_description
              )}
              error={errors.metaDescription}
              helperText={errors.metaDescription?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("metaDescription")?.length}
              </p>
            </span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              name="shortDesc"
              id="short-description"
              required
              fullWidth
              autoComplete="off"
              label="Short Description"
              placeholder="Maximum 600 characters"
              multiline
              rows={2}
              value={shortDescriptionText}
              onChange={shortDescriptionChangeHandler}
              inputProps={register("shortDesc", validations.short_description)}
              error={errors.shortDesc}
              helperText={errors.shortDesc?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("shortDesc")?.length}
              </p>
            </span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextEditor
              editorData={handleEditorChange}
              fileUploadHandler={fileUploadHandler}
              inputProps={register("description", validations.description)}
            />
            {errors.description?.message ? (
              <small style={{ color: "red" }}>
                {errors.description?.message}
              </small>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={12}>
            {keywords && keywords?.items.length ? (
              <Stack direction="row" spacing={1}>
                {keywords.items.map((item, key) => {
                  return (
                    <Chip
                      key={`chip_` + key}
                      label={item}
                      color="success"
                      variant="outlined"
                      onDelete={() => handleRelatedTagDelete(item)}
                    />
                  );
                })}
              </Stack>
            ) : (
              ""
            )}
            <TextField
              sx={{ mt: 2 }}
              autoComplete="off"
              fullWidth
              value={keywords.value}
              name="relatedTags"
              label="Related Tags"
              placeholder="Type data and press `Enter` `Tab` or `,`"
              onKeyDown={(e) => handleRelatedTagKeyDown(e)}
              onChange={(e) => handleRelatedTagChange(e)}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="related-links-label">Related Links</InputLabel>
                <Select
                  labelId="related-links-label"
                  id="related-links"
                  multiple
                  value={relatedLinks}
                  onChange={handleRelatedLinks}
                  input={<OutlinedInput label="Related Links" />}
                >
                  {blogData.map((blog, k) => (
                    <MenuItem key={`key_` + blog._id} value={blog._id}>
                      {blog.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

          <Button
            type="submit"
            disabled={isFetching ? true : false}
            variant="contained"
            startIcon={<Add />}
            sx={{ mt: 3, mb: 2 }}
          >
            {isFetching ? <CircularProgress color="secondary" /> : "Add"}
          </Button>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default AddBlog;
