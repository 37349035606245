/* eslint-disable react-hooks/exhaustive-deps */
import DOMPurify from "dompurify";
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { previewBlog, clearState, blogSelector } from './blogSlice';
import { Typography, Grid, Box } from "../../../utils/components/index";
import { makeStyles } from "@mui/styles";
import { createAlert, deleteAlert } from '../../alert/alertSlice';
import Alerts from '../../alert/alerts';

const useStyles = makeStyles((theme) => ({
  align_center: {
    textAlign: "center",
  },
}));
const PreviewBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { isError, responseMsg } =
    useSelector(blogSelector);

  const classes = useStyles();
  const blogsData = useSelector((state) => state.blogs.blogs);
  const data = blogsData.filter((data) => {
    return data._id === id;
  });

  // const bannerImage =
  //   data[0]?.banner_image !== undefined
  //     ? data[0]?.banner_image_location + data[0].banner_image
  //     : '../../../assets/images/no-image.png';
  const bannerImage =
    data[0]?.images?.banner !== undefined
      ? data[0].images.banner
      : "../../../assets/images/no-image.png";

  const descriptionData = data[0]?.description;
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(descriptionData),
  });

  useEffect(() => {
    dispatch(clearState());
    if (Object.keys(data) && data?.length) {
      dispatch(previewBlog(id));
    }
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: 'error',
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 2500);
    }
  }, [isError]);

  return (
    <React.Fragment>
      <Alerts />
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box
            component="img"
            sx={{
              height: 440,
              width: "100%",
            }}
            alt={data[0]?.bannerImageAltTag}
            src={bannerImage}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "center", margin: "15px" }}
        >
          <Typography
            variant="h5"
            component="span"
            style={{ color: "white", backgroundColor: "blue", padding: "5px" }}
          >
            By {data[0]?.author ? data[0]?.author : ""}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} className={classes.align_center}>
          <Typography variant="h3" component="div" gutterBottom>
            {data[0]?.title ? data[0]?.title : ""}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <div dangerouslySetInnerHTML={sanitizedData()}></div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PreviewBlog;
