/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { categoryList, clearState } from "./categorySlice"; //deleteCategory mention here
import { Link, useNavigate } from "react-router-dom";
import ReactTable from "../../../utils/react-table/ReactTable";
import { categorySelector } from "./categorySlice";
import Loader from "../../../utils/components/loader";

import {
  Add,
  Grid,
  Button,
  Typography,
  EditTwoToneIcon,
} from "../../../utils/components/index";

function CategoryList() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const categoriesData = useSelector((state) => state.categories.categories);
  let totalCategories = useSelector(
    (state) => state.categories.totalCategories
  );

  const { isFetching } = useSelector(categorySelector);

  const totalPages = useSelector((state) => {
    return state.categories.totalCategories > 0
      ? Math.ceil(state.categories.totalCategories / pageSize)
      : 0;
  });

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/category/edit/${data._id}`);
  }

  const columnsData = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (props) => {
        if (props.value === true) {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>Active</span>
          );
        } else {
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>Inactive</span>
          );
        }
      },
    },
    {
      Header: "Total Blogs",
      accessor: "totalBlogs",
      Cell: ({ value }) => (
        <div className="age-cell" gap="2px">
          <span
            style={{
              color: "olive",
              marginLeft: "0.5em",
              marginRight: "0.5em",
              fontWeight: "bold",
            }}
          >
            {`${value} Blog`}
          </span>
          {/* <span
            style={{
              color: "olive",
              // marginLeft: "0.5em",
              // marginRight: "0.5em",
              fontWeight: "bold",
            }}
          >
            Blog
          </span> */}
        </div>
      ),
      // Cell: ({ value }) => `${value} Blog related to this category`,
    },
    {
      Header: "Action",
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <EditTwoToneIcon
            className="cursor-pointer"
            onClick={() => editRowAction(originalRow)}
            titleAccess="Edit Category"
          />
        </React.Fragment>
      ),
    },
  ];

  const columns = useMemo(() => columnsData, [editRowAction]); //deleteRowAction will mention here beside of editrowaction when it will come on force,
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(categoryList(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ""}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            Categories
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/category/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={categoriesData}
            fetchData={fetchData}
            totalRecords={totalCategories}
            totalPages={totalPages}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CategoryList;
