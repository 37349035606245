/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import validations from "../../../utils/validations/validation.rules";
import {
  editBlog,
  updateBlog,
  blogSelector,
  clearState,
  addBlogEditorFile,
} from "./blogSlice";
import { allDomainList } from "../domains/domainSlice";
import { allCategoryList } from "../category/categorySlice";
import TextEditor from "../../Editor/TextEditor";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { createAlert, deleteAlert } from "../../alert/alertSlice";
import Alerts from "../../alert/alerts";

import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Chip,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  InputLabel,
  Select,
  FormControl,
} from "../../../utils/components/index";

const EditBlog = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearState());
    dispatch(allDomainList());
    dispatch(allCategoryList());
    if (Object.keys(data) && data?.length) {
      dispatch(editBlog(id));

      if (data[0]?.status === "publish") {
        setBlogStatusType(true);
      }

      // let url = data[0]?.domain?.url ? data[0]?.domain?.url : "";
      // setCurrentDomainUrl(url);

      setkeywords({ items: data[0].relatedTags, value: "" });
    }

    // if (Object.keys(domainData).length === 0 || domainData.length <= 0) {

    // }

    // if (
    //   Object.keys(categoriesData).length === 0 ||
    //   categoriesData.length <= 0
    // ) {

    // }
    // if (Object.keys(blogsData).length === 0 || blogsData.length <= 0) {  //this is for set related links from blogsData
    //   dispatch(blogList());
    // }
    dispatch(clearState());
  }, []);

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(blogSelector);

  const blogsData = useSelector((state) => state.blogs.blogs);
  const domainData = useSelector((state) => state.domains.domains);
  const categoriesData = useSelector((state) => state.categories.categories);

  const data = blogsData.filter((data) => {
    return data._id === id;
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: data[0],
  });

  // img open modal
  const [open, setOpen] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState(null);

  const handleImageOpen = (currentImage) => {
    setCurrentModalImage(currentImage);
    setOpen(true);
  };
  const handleImageClose = () => {
    setCurrentModalImage(null);
    setOpen(false);
  };

  // text editor
  const [textEditor, setTextEditor] = useState(null);
  const handleEditorChange = (editorRef) => {
    if (editorRef !== undefined) {
      setTextEditor(editorRef);
      setValue("description", editorRef);
    }
  };

  // img and file upload in text editor
  const fileUploadHandler = (file) => {
    let formData = new FormData();
    formData.append("editorFile", file);
    dispatch(clearState());
    return dispatch(addBlogEditorFile(formData)).then((resp) => {
      if (resp.payload?.statusCode === 200 && resp.payload?.data?.location) {
        return { location: resp.payload.data.location };
      } else {
        dispatch(
          createAlert({
            message:
              resp.payload.message !== undefined
                ? resp.payload.message
                : "Something went wrong. Try again later.",
            type: "error",
          })
        );
      }
    });
  };

  //related tags
  const [keywords, setkeywords] = useState({
    items: [],
    value: "",
  });

  const handleRelatedTagKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let value = evt.target.value.trim();
      if (value) {
        setkeywords({
          items: [...keywords.items, evt.target.value],
          value: "",
        });
      }
    }
  };

  const handleRelatedTagChange = (evt) => {
    setkeywords({
      items: [...keywords.items],
      value: evt.target.value,
    });
  };

  const handleRelatedTagDelete = (item) => {
    let newData = keywords.items.filter((i) => i !== item);
    setkeywords({ items: newData, value: keywords.value });
  };

  // meta description
  const [metaDescriptionText, setMetaDescriptionText] = useState("");
  const metaDescriptionChangeHandler = (event) => {
    setMetaDescriptionText(event.target.value);
  };

  // short description
  const [shortDescriptionText, setShortDescriptionText] = useState("");
  const shortDescriptionChangeHandler = (event) => {
    setShortDescriptionText(event.target.value);
  };

  // blog status
  const [blogStatusType, setBlogStatusType] = useState(false);
  const handleBlogStatusType = (e) => {
    const { value } = e.target;

    if (value === "publish") {
      setBlogStatusType(true);
    } else {
      setBlogStatusType(false);
    }
  };

  // domain change with title, slug, canonical url
  // const [currentDomainUrl, setCurrentDomainUrl] = useState("");

  // function onDomainChange() {
  //   // if use e in parameter -> "e.target.dataset.url" or else "this['data-url']"";
  //   let url = this["data-url"];
  //   let slug = getValues("slug");
  //   if (url && slug === "") {
  //     setValue("canonicalUrl", url);
  //   }
  //   if (url && slug) {
  //     let canonical_url = url + "/" + slug;
  //     setValue("canonicalUrl", canonical_url);
  //   }
  //   setCurrentDomainUrl(url);
  // }

  const onSubmit = (submit) => {
    dispatch(clearState());

    submit.textEditor = textEditor !== null ? textEditor : data[0].description;

    submit.metaDescription = metaDescriptionText
      ? submit.metaDescription
      : data[0].metaDescription;

    submit.shortDesc = shortDescriptionText
      ? submit.shortDesc
      : data[0].shortDesc;

    let banner_image = submit.bannerImg[0]?.name
      ? submit.bannerImg[0]
      : data[0]?.images?.banner
        ? data[0].images.banner
        : null;

    let thumbnail = submit.thumbnailImg[0]?.name
      ? submit.thumbnailImg[0]
      : data[0]?.images?.thumbnail
        ? data[0]?.images?.thumbnail
        : null;

    let formData = new FormData();
    formData.append("id", id);
    formData.append("domainId", submit.domainId); // in edit the right side frontend key same as left side backend key.
    formData.append("categoryId", submit.categoryId);
    formData.append("author", submit.author);
    formData.append("thumbnailImg", thumbnail);
    formData.append("bannerImg", banner_image);
    formData.append("imgAltTag", submit.imgAltTag);
    formData.append("title", submit.title);
    formData.append("slug", submit.slug);
    formData.append("metaTitle", submit.metaTitle);
    formData.append("canonicalUrl", submit.canonicalUrl);
    formData.append("metaDescription", submit.metaDescription);
    formData.append("shortDesc", submit.shortDesc);
    formData.append("status", submit.status);
    if (submit.publishDate) {
      formData.append("publishDate", submit.publishDate);
    }
    formData.append("description", submit.textEditor);
    formData.append("relatedTags[]", keywords.items);
    dispatch(updateBlog(formData));
  };

  useEffect(() => {
    if (isSuccess && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "success",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 1000);
    }
    if (isError && responseMsg) {
      dispatch(
        createAlert({
          message: responseMsg,
          type: "error",
        })
      );
      setTimeout(() => {
        dispatch(deleteAlert());
        dispatch(clearState());
      }, 3000);
    }
  }, [isSuccess, isError]);

  // navigate to list
  const navigateToBloglist = () => {
    navigate("/blog/list");
  };

  // this is for related links(collections of all blog from the list)
  // const [relatedLinks, setRelatedLinks] = useState(
  //   data[0]?.relatedLinks ? data[0]?.relatedLinks : []
  // );
  // const handleRelatedLinks = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRelatedLinks(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value
  //   );
  // };

  return (
    <React.Fragment>
      <Typography variant="h5" component="div">
        Edit Blog
      </Typography>
      <Alerts />
      <Box
        component="form"
        noValidate
        sx={{ mt: 5 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              defaultValue={data[0]?.domain?.id ? data[0].domain.id : ""}
              id="domainId"
              label="Domain *"
              inputProps={register("domainId", validations.domain_id)}
              error={errors.domainId}
              helperText={errors.domainId?.message}
            >
              {domainData.map((domain) => (
                <MenuItem
                  key={domain.id}
                  value={domain.id}
                  data-url={domain.url} // <- if whenever we need to choose another section of any field like here 'url', this is the way to get the value of this key. write "data-" then the section "data-url"
                // onClick={onDomainChange}
                >
                  {domain.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              // name="categoryId"
              // defaultValue={data?.categoryId}
              defaultValue={data[0]?.category?.id ? data[0].category.id : ""}
              id="categoryId"
              label="Blog Category *"
              inputProps={register("categoryId", validations.category_id)}
              error={errors.category_id}
              helperText={errors.category_id?.message}
            >
              {categoriesData.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="author"
              label="Blog Author"
              name="author"
              autoComplete="off"
              inputProps={register("author", validations.blog_author)}
              error={errors.author}
              helperText={errors.author?.message}
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <TextField
              focused
              autoComplete="off"
              name="thumbnailImg"
              fullWidth
              required
              label="Thumbnail Image (Preferable size 762x400)"
              type="file"
              id="thumbnailImg"
              accept="image/*"
              defaultValue={""}
              inputProps={register("thumbnailImg")}
              error={errors.thumbnail}
              helperText={errors.thumbnail?.message}
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <Button
              color="success"
              onClick={() =>
                handleImageOpen(
                  data[0]?.images?.thumbnail ? data[0].images.thumbnail : null
                )
              }
            >
              Open Image
            </Button>
          </Grid>

          <Grid item xs={5} sm={5}>
            <TextField
              focused
              required
              autoComplete="off"
              name="bannerImg"
              fullWidth
              label="Banner Image (Preferable size 1920x540)"
              type="file"
              id="bannerImg"
              accept="image/*"
              defaultValue={null}
              inputProps={register("bannerImg")}
              error={errors.banner_image}
              helperText={errors.banner_image?.message}
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <Button
              color="success"
              onClick={() =>
                handleImageOpen(
                  data[0]?.images?.banner ? data[0].images.banner : null
                )
              }
            >
              Open Image
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="bannerImageAltTag"
              required
              fullWidth
              id="bannerImageAltTag"
              label="Banner Image Alt Tag"
              inputProps={register(
                "imgAltTag",
                validations.banner_image_alt_tag
              )}
              error={errors.bannerImageAltTag}
              helperText={errors.bannerImageAltTag?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="title"
              required
              fullWidth
              label="Title"
              type="text"
              id="title"
              inputProps={register("title", validations.title)}
              error={errors.title}
              helperText={errors.title?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              focused
              disabled
              autoComplete="off"
              name="slug"
              fullWidth
              label="Slug"
              type="text"
              id="slug"
              inputProps={register("slug", validations.slug)}
              error={errors.slug}
              helperText={errors.slug?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="off"
              name="metaTitle"
              required
              fullWidth
              label="Meta Title"
              type="text"
              id="metaTitle"
              inputProps={register("metaTitle", validations.seo_title)}
              error={errors.metaTitle}
              helperText={errors.metaTitle?.message}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              autoComplete="off"
              name="canonicalUrl"
              fullWidth
              label="Canonical URL"
              type="text"
              id="canonicalUrl"
              inputProps={register("canonicalUrl", validations.canonical)}
              error={errors.canonicalUrl}
              helperText={errors.canonicalUrl?.message}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              name="metaDescription"
              id="meta-description"
              required
              fullWidth
              autoComplete="off"
              label="Meta Description"
              multiline
              rows={1}
              value={data[0]?.metaDescriptionText}
              onChange={metaDescriptionChangeHandler}
              inputProps={register(
                "metaDescription",
                validations.meta_description
              )}
              error={errors.metaDescription}
              helperText={errors.metaDescription?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("metaDescription")?.length}
              </p>
            </span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              name="shortDesc"
              id="short-description"
              required
              fullWidth
              autoComplete="off"
              label="Short Description"
              multiline
              rows={2}
              value={data[0]?.shortDescriptionText}
              onChange={shortDescriptionChangeHandler}
              inputProps={register("shortDesc", validations.short_description)}
              error={errors.shortDesc}
              helperText={errors.shortDesc?.message}
            />
            <span>
              <p style={{ color: "green" }}>
                Character Count (Including space):{" "}
                {getValues("shortDesc")?.length}
              </p>
            </span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextEditor
              inputProps={register("description", validations.description)}
              editorData={handleEditorChange}
              initialValue={data[0]?.description}
              fileUploadHandler={fileUploadHandler}
            />
            {errors.description?.message ? (
              <small style={{ color: "red" }}>
                {errors.description?.message}
              </small>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl css-md26zr-MuiInputBase-root-MuiOutlinedInput-root"
              sx={{ width: "100%" }}
            >
              <InputLabel id="blog-type-label">Blog Type *</InputLabel>
              <Select
                disabled={
                  data[0]?.status && data[0]?.status === "publish"
                    ? true
                    : false
                }
                fullWidth
                required
                name="status"
                id="blog-type"
                defaultValue={data[0]?.status}
                label="Blog Type *"
                onChange={handleBlogStatusType}
                error={errors.status}
                inputProps={register("status", validations.blog_type)}
              >
                <MenuItem key="draftkey" value="draft">
                  Draft
                </MenuItem>
                <MenuItem key="publishkey" value="publish">
                  Publish
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {blogStatusType ? (
            <Grid item xs={12} sm={6}>
              <TextField
                focused
                autoComplete="off"
                name="publishDate"
                required
                fullWidth
                format="yyyy-MM-dd"
                label="Publish Date"
                type="date"
                id="publishDate"
                defaultValue={
                  data[0]?.publishDate
                    ? format(parseISO(data[0].publishDate), "yyyy-MM-dd")
                    : ""
                }
                inputProps={register("publishDate", validations.publish_date)}
                error={errors.publishDate}
                helperText={errors.publishDate?.message}
              />
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} sm={12}>
            {keywords && keywords?.items?.length > 0 ? (
              <Stack direction="row" spacing={1}>
                {keywords.items.map((item, key) => {
                  if (item) {
                    return (
                      <Chip
                        key={`chip_` + key}
                        label={item}
                        color="success"
                        variant="outlined"
                        onDelete={() => handleRelatedTagDelete(item)}
                      />
                    );
                  }
                })}
              </Stack>
            ) : (
              ""
            )}
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              autoComplete="off"
              name="relatedTags"
              label="Related Tags"
              placeholder="Type data and press `Enter` `Tab` or `,`"
              value={keywords.value}
              onKeyDown={(e) => handleRelatedTagKeyDown(e)}
              onChange={(e) => handleRelatedTagChange(e)}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="related-links-label">Related Links</InputLabel>
              <Select
                labelId="related-links-label"
                id="related-links"
                multiple
                value={relatedLinks}
                onChange={handleRelatedLinks}
                input={<OutlinedInput label="Related Links" />}
              >
                {blogsData.map((blog) => {
                  return blog._id !== id ? (
                    <MenuItem key={`key_` + blog._id} value={blog._id}>
                      {blog.title}
                    </MenuItem>
                  ) : null;
                })}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={1} sm={1}>
            <Button
              type="submit"
              disabled={isFetching ? true : false}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {isFetching ? <CircularProgress color="secondary" /> : "Update"}
            </Button>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={navigateToBloglist}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleImageClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <Box
            component="img"
            sx={{
              height: 600,
              width: 800,
              maxHeight: { xs: 500, md: 500 },
              maxWidth: { xs: 800, md: 800 },
            }}
            alt="Banner Image"
            src={
              currentModalImage !== null
                ? currentModalImage
                : "../../../assets/images/no-image.png"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleImageClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditBlog;
