import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addLanguage = createAsyncThunk(
  'language/add', //this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let addLanguage = await HTTPClient.http_post('languages/store', postData); // and this from backend, linkup the frontend url with backend url here.
      return addLanguage;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const languageList = createAsyncThunk(
  'language/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `languages`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `languages?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&search=${searchValue}`;
        }
      }

      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteLanguage = createAsyncThunk(
  'language/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteLanguage = await HTTPClient.http_delete(`languages/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteLanguage;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editLanguage = createAsyncThunk(
  'language/update', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    let id = postData.id;
    delete postData[id];
    try {
      let editLanguage = await HTTPClient.http_patch(
        'languages/' + id,
        postData
      ); // this is from backend url
      return editLanguage;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  languages: [],
  totalLanguages: 0,
};

export const languageSlice = createSlice({
  name: 'languages',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
  },
  extraReducers: {
    [addLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [addLanguage.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.languages.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [addLanguage.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [languageList.pending]: (state) => {
      state.isFetching = true;
    },
    [languageList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.languages = payload.data;
        state.totalLanguages = payload.total;
        state.isFetching = false;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [languageList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [editLanguage.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.languages.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [editLanguage.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [deleteLanguage.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteLanguage.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.languages = state.languages.filter(
          (language) => language._id !== payload.removeId
        );
        state.totalLanguages = state.totalLanguages - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteLanguage.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = languageSlice.actions;
export const languageSelector = (state) => state.languages;
