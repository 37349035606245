import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../outside-login/login/authSlice';
import { useNavigate, Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';

import { 
Box,
CssBaseline,
Toolbar
} from '../../utils/components/index';

const InsideLayout = (props) => {
  const drawerWidth = 240;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <React.Fragment>
      <div className='inside-layout'>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Header {...{ mobileOpen, container, handleDrawerToggle, drawerWidth, logoutHandler }} />
          <Sidebar {...{ mobileOpen, container, handleDrawerToggle, drawerWidth }} />
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              p: 2,
              width: { sm: `calc(100% - ${props.drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Outlet {...{ drawerWidth }}></Outlet>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default InsideLayout;
