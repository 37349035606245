/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginUser, authSelector, clearState } from './authSlice';
import validations from '../../../utils/validations/validation.rules';
import getToken from '../../../utils/token';

import {
  Box,
  Avatar,
  Button,
  Container,
  TextField,
  Typography,
  CssBaseline,
  CircularProgress,
  LockOutlinedIcon
} from '../../../utils/components/index';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      Centersource Technologies AB {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isLoggedIn = getToken();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isFetching, isSuccess, isError, responseMsg } =
    useSelector(authSelector);

  const onSubmit = (data) => {
    dispatch(clearState());
    dispatch(loginUser(data));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    } else if (isSuccess) {
      dispatch(clearState());
      navigate('/dashboard');
    } else if (isError) {
      alert(responseMsg);
      dispatch(clearState());
    }
  }, [isSuccess, isError, isLoggedIn]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            id="email"
            name="email"
            label="Email Address"
            required
            fullWidth
            autoComplete="off"
            autoFocus
            margin="normal"
            error={errors.email !== undefined ? true : false}
            helperText={
              errors.email !== undefined && errors.email.message !== undefined
                ? errors.email.message
                : ''
            }
            {...register('email', validations.email)}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            required
            fullWidth
            type="password"
            autoComplete="off"
            margin="normal"
            error={errors.password !== undefined ? true : false}
            helperText={
              errors.password !== undefined &&
              errors.password.message !== undefined
                ? errors.password.message
                : ''
            }
            {...register('password', validations.password)}
          />
          <Button
            type="submit"
            fullWidth
            disabled={isFetching ? true : false}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {isFetching ? <CircularProgress color="secondary" /> : 'Sign In'}
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Login;
