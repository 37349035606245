import { createTheme } from '@mui/material/styles';

// let themeConfig = createTheme({
//   palette: {
//     primary: {
//       // dark: '#0039cb',
//       main: '#0039cb',
//       // light: '#768fff',
//     },
//     secondary: {
//       main: '#2962ff',
//     },
//   },
// });

const themeConfig = createTheme({
  palette: {
    primary: {
      light: '#38006b',
      main: '#6a1b9a',
      dark: '#9c4dcc',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7e57c2',
      main: '#4d2c91',
      dark: '#b085f5',
      contrastText: '#000',
    },
  },
});

export default themeConfig;
