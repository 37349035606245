import { makeStyles } from '@mui/styles';

// export const useStyles = makeStyles(() => ({

//   navlinks: {
//     color: blueGrey["A400"],
//     "& :hover , &:hover div": {
//       color: blue["A200"],
//     },
//     " & div": {
//       color: blueGrey["A400"],
//     },
//   },
//   activeNavlinks: {
//     color: blue["A700"],
//     "& div": {
//       color: blue["A700"],
//     },
//   },
//   navButton: {
//     width: " 100%",
//     textTransform: "capitalize",
//   },
// }));

const SidebarStyle = makeStyles((theme) => ({
  sidebarBackground: {
    backgroundColor: theme.paletteprimary.main,
  },
}));

export default SidebarStyle;