/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, Grid, Typography, Button,EditTwoToneIcon } from "../../../utils/components/index";
import { Link, useNavigate } from "react-router-dom";
import { tagList, tagSelector, clearState } from "./tagSlice";
import ReactTable from "../../../utils/react-table/ReactTable";
import Loader from "../../../utils/components/loader";

const TagList = () => {
  let navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const tagsData = useSelector((state) => state.tags.tags);
  let totalTags = useSelector((state) => state.tags.totalTags);

  const { isFetching } = useSelector(tagSelector);

  const totalPages = useSelector((state) => {
    return state.tags.totalTags > 0
      ? Math.ceil(state.tags.totalTags / pageSize)
      : 0;
  });

  function editRowAction(data) {
    dispatch(clearState());
    return navigate(`/tag/edit/${data._id}`);
  }

  const columnsData = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Meta Title",
      accessor: "metaTitle",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Meta Description",
      accessor: "metaDescription",
      Cell: ({ value }) => {
        return (
          <div className="age-cell" gap="2px">
            <span style={{ color: "teal", fontWeight: "bold" }}>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: (originalRow, rowIndex) => (
        <React.Fragment>
          <EditTwoToneIcon
            className="cursor-pointer"
            onClick={() => editRowAction(originalRow)}
            titleAccess="Edit Tag"
          />
        </React.Fragment>
      ),
    },
    // {
    //   Header: "Action",
    //   accessor: (originalRow) => (
    //     <React.Fragment>
    //       <EditTwoToneIcon
    //         className="cursor-pointer"
    //         onClick={() => deleteRowAction(originalRow)}
    //         titleAccess="Delete Tag"
    //       />
    //     </React.Fragment>
    //   ),
    // },
  ];

  const dispatch = useDispatch();
  const columns = useMemo(() => columnsData, [editRowAction]);
  const fetchData = useCallback((pageIndex, search, page_size) => {
    let paginationData = {
      pageNumber: pageIndex,
      pageSize: page_size,
      searchValue: search,
    };
    Promise.resolve(dispatch(clearState()))
      .then(() => dispatch(tagList(paginationData)))
      .then(() => setPageSize(page_size))
      .then(() => dispatch(clearState()));
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2} display="flex">
        {isFetching ? <Loader /> : ""}
        <Grid item xs={8}>
          <Typography variant="h5" component="div">
            Blog Related Tags
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            component={Link}
            to="/tag/add"
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <ReactTable
            columns={columns}
            data={tagsData}
            fetchData={fetchData}
            totalRecords={totalTags}
            totalPages={totalPages}
          />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
};

export default TagList;
