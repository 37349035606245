import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addTag = createAsyncThunk(
    'tag/add',
    async (postData, { rejectWithValue }) => {
        try {
            let addTag = await HTTPClient.http_post('tags/store', postData);
            return addTag;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const tagList = createAsyncThunk(
    'tag/list',
    async (paginationData, thunkAPI) => {
        try {
            let url;
            if (paginationData === undefined) {
                url = `tags/`;
            } else {
                let { pageNumber, pageSize, searchValue } = paginationData;
                url = `tags?page=${pageNumber + 1}&limit=${pageSize}`;
                if (searchValue !== '') {
                    url = url + `&search=${searchValue}`;
                }
            }
            const list = await HTTPClient.http_get(url)
                .then((resp) => {
                    if (resp.data && resp.statusCode === 200) {
                        return resp;
                    } else {
                        throw new Error(resp);
                    }
                })
                .catch((err) => {
                    throw new Error(err);
                });
            return list;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const editTag = createAsyncThunk(
    'tag/edit', // this is frontend url, this is a action which will show is redux actions
    async (postData, { rejectWithValue }) => {
      try {
        let id = postData.id;
        delete postData[id];
        let editTag = await HTTPClient.http_patch(
          'tags/' + id,
          postData
        ); // this is from backend url
        return editTag;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  );


export const deleteTag = createAsyncThunk(
    'tag/remove',
    async (id, { thunkAPI }) => {
        try {
            const deleteTag = await HTTPClient.http_delete(`tags/${id}`)
                .then((resp) => {
                    if (resp.statusCode === 200) {
                        resp.removeId = id;
                        return resp;
                    } else {
                        throw new Error(resp);
                    }
                })
                .catch((err) => {
                    throw new Error(err);
                });
            return deleteTag;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

const initialState = {
    tags: [],
    totalTags: 0,
};

export const tagSlice = createSlice({
    name: 'tags',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState);
        },
        clearState: (state) => {
            state.isFetching = false;
            state.isSuccess = false;
            state.isError = false;
            return state;
        },
    },
    extraReducers: {
        [addTag.pending]: (state) => {
            state.isFetching = true;
        },
        [addTag.fulfilled]: (state, { payload }) => {
            if (payload !== undefined && payload.statusCode === 201) {
                state.tags.push(payload.data);
                state.isSuccess = true;
                state.responseMsg =
                    payload !== undefined && payload.message
                        ? payload.message
                        : 'Something went wrong. Try again later.';
                return state;
            } else {
                state.isError = true;
                state.responseMsg =
                    payload !== undefined && payload.message
                        ? payload.message
                        : 'Something went wrong. Try again later.';
            }
        },
        [addTag.rejected]: (state, { payload }) => {
            state.isError = true;
            state.responseMsg =
                payload !== undefined && payload.message
                    ? payload.message
                    : 'Something went wrong. Try again later.';
        },
        [tagList.pending]: (state) => {
            state.isFetching = true;
        },
        [tagList.fulfilled]: (state, { payload }) => {
            if (payload.data !== undefined && payload.statusCode === 200) {
                state.tags = payload.data;
                state.totalTags = payload.total;
                state.isSuccess = true;
            } else {
                state.isError = true;
            }
            return state;
        },
        [tagList.rejected]: (state, { payload }) => {
            state.isError = true;
            state.responseMsg =
                payload !== undefined && payload.message
                    ? payload.message
                    : 'Something went wrong. Try again later.';
        },
        [editTag.pending]: (state) => {
            state.isFetching = true;
          },
        [editTag.fulfilled]: (state, { payload }) => {
        if (payload !== undefined && payload.statusCode === 200) {
            state.tags.push(payload.data);
            state.isSuccess = true;
            state.responseMsg =
            payload !== undefined && payload.message
                ? payload.message
                : 'Something went wrong. Try again later.';
        } else {
            state.isError = true;
            state.responseMsg =
            payload !== undefined && payload.message
                ? payload.message
                : 'Something went wrong. Try again later.';
        }
        },
        [editTag.rejected]: (state, { payload }) => {
        state.isError = true;
        state.responseMsg =
            payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        },
        [deleteTag.pending]: (state) => {
            state.isFetching = true;
        },
        [deleteTag.fulfilled]: (state, { payload }) => {
            if (payload !== undefined && payload.statusCode === 200) {
                state.tags = state.tags.filter(
                    (tag) => tag._id !== payload.removeId
                );
                state.totalTags = state.totalTags - 1;
                state.responseMsg =
                    payload !== undefined && payload.message
                        ? payload.message
                        : 'Something went wrong. Try again later.';
                return state;
            } else {
                state.isError = true;
                state.responseMsg =
                    payload !== undefined && payload.message
                        ? payload.message
                        : 'Something went wrong. Try again later.';
                return state;
            }
        },
        [deleteTag.rejected]: (state, { payload }) => {
            state.isError = true;
            state.responseMsg =
                payload !== undefined && payload.message
                    ? payload.message
                    : 'Something went wrong. Try again later.';
            return state;
        },
    },
});

export const { clearState } = tagSlice.actions;
export const tagSelector = (state) => state.tags;

