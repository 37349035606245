import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HTTPClient } from '../../../utils/services/http.services';

export const addCategory = createAsyncThunk(
  'category/add', // frontend url
  async (postData, { rejectWithValue }) => {
    try {
      let addCategory = await HTTPClient.http_post(
        'blog/category/store',
        postData
      ); // backend url
      return addCategory;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const categoryList = createAsyncThunk(
  'category/list',
  async (paginationData, thunkAPI) => {
    try {
      let url;
      if (paginationData === undefined) {
        url = `blog/category`;
      } else {
        let { pageNumber, pageSize, searchValue } = paginationData;
        url = `blog/category?page=${pageNumber + 1}&limit=${pageSize}`;
        if (searchValue !== '') {
          url = url + `&search=${searchValue}`;
        }
      }
      const list = await HTTPClient.http_get(url)
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return list;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const activeCategoryList = createAsyncThunk(
  'active-category',
  async (thunkAPI) => {
    try {
      const activeCategory = await HTTPClient.http_get('blog/category/get-categories/active')
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return activeCategory;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const allCategoryList = createAsyncThunk(
  'all-category',
  async (thunkAPI) => {
    try {
      const allCategory = await HTTPClient.http_get('blog/category/get-categories/all')
        .then((resp) => {
          if (resp.data && resp.statusCode === 200) {
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return allCategory;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editCategory = createAsyncThunk(
  'category/edit', // this is frontend url, this is a action which will show is redux actions
  async (postData, { rejectWithValue }) => {
    try {
      let id = postData.id;
      delete postData[id];
      let editCategory = await HTTPClient.http_patch(
        'blog/category/' + id,
        postData
      ); // this is from backend url
      return editCategory;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  'category/remove',
  async (id, { thunkAPI }) => {
    try {
      const deleteCategory = await HTTPClient.http_delete(`blog/category/${id}`)
        .then((resp) => {
          if (resp.statusCode === 200) {
            resp.removeId = id;
            return resp;
          } else {
            throw new Error(resp);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
      return deleteCategory;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  categories: [],
  totalCategories: 0,
};

export const categorySlice = createSlice({
  name: 'categories',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    },
  },
  extraReducers: {
    [addCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [addCategory.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 201) {
        state.categories.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [addCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [categoryList.pending]: (state) => {
      state.isFetching = true;
    },
    [categoryList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.categories = payload.data;
        state.totalCategories = payload.total;
        state.isSuccess = true;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [categoryList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [activeCategoryList.pending]: (state) => {
      state.isFetching = true;
    },
    [activeCategoryList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.categories = payload.data;
        state.totalCategories = payload.total;
        state.isSuccess = true;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [activeCategoryList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [allCategoryList.pending]: (state) => {
      state.isFetching = true;
    },
    [allCategoryList.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.categories = payload.data;
        state.totalCategories = payload.total;
        state.isSuccess = true;
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [allCategoryList.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [editCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [editCategory.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.categories.push(payload.data);
        state.isSuccess = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
      }
    },
    [editCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
    },
    [deleteCategory.pending]: (state) => {
      state.isFetching = true;
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      if (payload !== undefined && payload.statusCode === 200) {
        state.categories = state.categories.filter(
          (category) => category._id !== payload.removeId
        );
        state.totalCategories = state.totalCategories - 1;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      } else {
        state.isError = true;
        state.responseMsg =
          payload !== undefined && payload.message
            ? payload.message
            : 'Something went wrong. Try again later.';
        return state;
      }
    },
    [deleteCategory.rejected]: (state, { payload }) => {
      state.isError = true;
      state.responseMsg =
        payload !== undefined && payload.message
          ? payload.message
          : 'Something went wrong. Try again later.';
      return state;
    },
  },
});

export const { clearState } = categorySlice.actions;
export const categorySelector = (state) => state.categories;

