import React from 'react'

const NotFound = () => {
  return (
    <React.Fragment>
        <p>404 | Not Found</p>
    </React.Fragment>
  )
}

export default NotFound;